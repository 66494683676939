@import './variables.less';

.header-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: url(../public/img/YTL_Apollo.jpg) no-repeat 10px center;
  background-size: auto 50px;
  height: @header-banner-height;
  background-color: #fff;
  color: #002c77;
  border-bottom: 1px solid #c8c8c8;
  position: relative;
  z-index: @header-banner-z-index;
}
.organization {
  padding-left: 70px;
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
}
.user-actions {
  display: flex;
}
.language-selection {
  white-space: nowrap;
  margin-right: 20px;

  input {
    display: none;
  }
  input:checked + label {
    font-weight: 600;
    border-bottom: 1px solid #555;
    cursor: default;
  }

  label {
    font-size: 12px;
    text-transform: uppercase;
    cursor: pointer;
    padding-left: 2px;
    padding-right: 2px;
  }
}
.user-area {
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  padding-left: 4px;
}
#mock {
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  border: 1px solid #217d96;
  border-radius: 4px;
  padding: 2px 10px;
  &:hover {
    background: #a1d2df;
  }
}
#vetuma-logout {
  margin-left: 18px;
  margin-right: 8px;
  background: url('../public/img/logout-icon.png') no-repeat;
  width: 25px;
  height: 22px;
}
