@import '~@digabi/exam-engine-core/dist/main.css';
@import (css) '../node_modules/normalize.css/normalize.css';
@import (css) '../node_modules/source-sans/source-sans-3.css';
@import '../node_modules/@fortawesome/fontawesome-free/css/all.css';
@import (css) '../node_modules/spin.js/spin.css';

/****************************
 Common fonts, sizes, colors
*****************************/

@base-secondary-button-background: #fefefe;
@base-secondary-button-text: #6d6d6d;
@base-brand-blue-shadow-transparent: rgba(0, 110, 210, 0.4);
@base-brand-blue-border-transparent: rgba(0, 110, 210, 0.7);
@base-link-font-blue: @base-brand-blue;
@base-header-font-blue: @base-brand-blue;
@base-color-almost-black: #555555;
@base-green-button: #5acd46;
@base-green-button-hover: #32aa14;
@base-error-text-transparent: rgba(240, 60, 60, 0.6);
@base-error-shadow-transparent: rgba(240, 60, 60, 0.2);
@base-input-border-color: #e6e6e6;
@base-dropdown-border-color: @base-input-border-color;
@base-input-border-width: 2px;

@clickable-color: @base-blue-button;
@clickable-hover-color: @base-blue-button-hover;
@clickable-disabled-color: @base-disabled-button;

@SaBackground: #fafafa;
@SAPageBannerTextColor: @base-page-banner-text-color;

@sa-error-text-color: @base-error-text;
@sa-error-background-color: #fff;
@text-font: 'Source Sans 3', sans-serif;
@text-size: 18px;
@text-color: #263d43;
@big-heading-text-color: #505050;
@score-table-text-color: #999;
@score-table-disabled-color: #e6e6e6;

@input-text-size: 16px;

@highlight-color: @base-brand-blue;
@background-alternative-color: #f2f2f2;
@background-highlight-color: #e1f0ff;
@text-color-on-highlight: #000;

@layout-width: 1500px;
@body-line-height: 25px;

@small-screen-margin: 12px;

@bar-graded: hsl(218, 58%, 76%);
@bar-finished: hsl(218 28% 39% / 1);

/******************************
   Default Style definitions
*******************************/

*,
*:before,
*:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

body {
  background: @SaBackground;
  font-family: @text-font;
  font-size: @text-size;
  line-height: @body-line-height;
  color: @text-color;
  margin: 0;
}
.hidden {
  display: none !important;
}
h1 {
  .header1();
  margin: 50px 0 10px;
}
h2 {
  .header2();
  margin: 10px 0 10px;
}
h3 {
  .header3();
  margin: 20px 0;
}
h4 {
  .header4();
  margin: 20px 0;
}

p {
  margin: 20px 0;
}

strong {
  font-weight: 700;
}

input:not([type='file']):not([type='radio']),
textarea {
  .text-input();
}

textarea {
  display: block;
  resize: vertical;
}

input:disabled,
textarea:disabled {
  border-color: #d6d6d6;
  background-color: #f9f9f9;
}

a {
  color: @clickable-color;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: @clickable-hover-color;
  }
  &.disabled {
    color: @clickable-disabled-color;
    pointer-events: none;
  }
}

table {
  border-collapse: collapse;
}

table button {
  .sa-button-table();
}

/******************************
   Common SA styles
*******************************/

.content {
  .centered-content();
}

.disabled {
  color: @clickable-disabled-color;
  * {
    color: @clickable-disabled-color;
  }
}

.goBack {
  position: relative;
  color: @clickable-color;
  display: inline-block;
  a {
    .arrow-left-before();
    .action-link();
    position: relative;
    display: inline-block;
    left: 25px;
  }
  a.disabled {
    color: @clickable-disabled-color;
    pointer-events: none;
  }
}

button {
  .sa-button-big();
}

.button {
  display: inline-block;
  text-decoration: none;
  .sa-button-big();
}

.disabled button,
button:disabled,
.disabled button:hover,
button:disabled:hover {
  .disabled-button();
}

#floating-error {
  .floating-error-panel();
  .error-symbol {
    color: #9b0000;
    font-size: 32px;
    position: absolute;
    top: 20px;
  }
  .message {
    padding-top: 60px;
    padding-bottom: 20px;
    margin: 0 auto;
    max-width: 640px;
    color: @text-color;
  }
  button {
    width: unset;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 40px;
  }
  p {
    margin: 0;
  }
}

#disabled-background {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  opacity: 0.6;
  height: 100%;
  width: 100%;
}

/************************
    Component Mixins
*************************/

.header1() {
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  color: @big-heading-text-color;
}
.header2() {
  font-weight: 700;
  font-size: 24px;
  line-height: @body-line-height;
  color: @big-heading-text-color;
}
.header3() {
  font-weight: 600;
  font-size: 18px;
  line-height: @body-line-height;
}
.header4() {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.button-font() {
  font-family: @text-font;
  font-weight: 400;
  font-size: 16px;
  line-height: @body-line-height;
}

.sa-button-base() {
  .button-font();
  text-align: center;
  background: @clickable-color;
  color: white;
  padding: 0.25rem 1rem;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    background-color: @clickable-hover-color;
  }
}

.sa-button() {
  .sa-button-base();
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);

  &:hover {
    background: @clickable-hover-color;
    color: white;
  }
  &:active {
    box-shadow: none;
  }
}

.sa-button-auto() {
  .sa-button();
  width: auto;
}
.sa-button-big() {
  .sa-button-auto();
}

.sa-button-medium() {
  .sa-button-big();
}

.sa-button-small() {
  .sa-button-base();
  font-size: 14px;
  height: 30px;
  line-height: 26px;
  border-radius: 3px;
}

.sa-secondary-button {
  background: @base-secondary-button-background;
  color: @base-secondary-button-text;
  border: 1px #c8c8c8 solid;
  box-shadow: 0 0 3px #ddd;
  &[disabled],
  &.disabled {
    border-color: #dfdfdf;
    color: #dfdfdf;
    background-color: @base-secondary-button-background;
    box-shadow: none;
    &:hover {
      border-color: #dfdfdf;
      color: #dfdfdf;
      background-color: @base-secondary-button-background;
      box-shadow: none;
    }
  }
  &:hover {
    background-color: #f8f8f8;
    color: #333333;
  }
}

.sa-button-table() {
  .button-font();
  .sa-secondary-button();
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  border-radius: 2px;
  margin: 0;
  padding: 1px 20px;
  width: auto;
  height: auto;
  white-space: nowrap;
}

.disabled-button() {
  background: @clickable-disabled-color;
  cursor: default;
  color: white;
  box-shadow: none;
}

.utf-char-before(@content, @left: 0) {
  &:before {
    display: inline-block;
    position: absolute;
    left: @left;
    font-size: 32px;
    line-height: 32px;
    text-decoration: none;
    content: @content;
    font-weight: 400;
    overflow: hidden; // IE11
    height: 28px; // hacks
  }
}

.arrow-left-before() {
  .utf-char-before('\2190', -25px);
  &:before {
    top: -1px;
  }
}

.action-link() {
  font-family: @text-font;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-decoration: underline;
  color: @clickable-color;
  i {
    padding-right: 6px;
  }
  &:hover {
    color: @clickable-hover-color;
  }
}

i.fa-times-circle {
  position: relative;
  top: 0;
  padding-right: 1px;
}

.button-as-link() {
  .action-link();
  width: auto; // IE
  width: initial;
  height: auto; // IE
  height: initial;
  text-align: left;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  box-shadow: none;
  outline: none;
}

.centered-content(@width: @layout-width) {
  max-width: @width;
  padding: 0;
}

.glow() {
  box-shadow: 0 0 5px @base-brand-blue-shadow-transparent !important;
  outline: none;
}

.highlight-border() {
  .glow();
  border-color: @base-brand-blue-border-transparent !important;
}

.highlight-borders-when-focused() {
  &:focus,
  &:required:focus {
    .highlight-border();
  }
}

.error-panel() {
  font-weight: 400;
  font-size: 18px;
  color: @sa-error-text-color;
  background: @sa-error-background-color;
  box-shadow: 0 2px 3px #ddd;
}

.floating-error-panel() {
  .error-panel();
  line-height: 37px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-height: 60px;
  z-index: 1000;
  padding-left: 40px;
  padding-right: 40px;
}

.text-input() {
  background: white;
  border: @base-input-border-width solid @base-input-border-color;
  padding: 3px;
  font-family: @text-font;
  font-size: @input-text-size;
  font-weight: @input-text-weight;
  color: @text-color;
  .highlight-borders-when-focused();
}

.notification() {
  border: #c8d4db 1px solid;
  border-radius: 3px;
  background-color: #f7fdff;
  padding: 7px 30px 6px;
  font-size: 14px;
  font-weight: 400;
}

.confirmation-dialog() {
  @popup-width: 600px;
  @popup-box-shadow: 0 0 6px #ccc;
  position: absolute;
  width: @popup-width;
  padding: 25px;
  padding-top: 20px;
  bottom: 51px;
  right: 10px;
  background-color: #ffffff;
  box-shadow: @popup-box-shadow;
  white-space: normal;
  text-align: left;
  header {
    font-weight: 600;
    font-size: 18px;
    line-height: @body-line-height;
  }
  p {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 300;
  }
  button {
    .sa-secondary-button();
    width: 150px;
    padding: 8px 10px;
    float: none;
    font-weight: 600;
    margin-right: 8px;
  }
  z-index: 100;
}
.confirmation-dialog-triangle(@button-to-be-confirmed-width) {
  @triangle-size: 20px;
  @triangle-position-right: (@button-to-be-confirmed-width / 2);
  &:before {
    content: '';
    width: @triangle-size;
    height: @triangle-size;
    background-color: #ffffff;
    position: absolute;
    bottom: -10px;
    right: @triangle-position-right;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    box-shadow: @popup-box-shadow;
  }
  &:after {
    content: '';
    background-color: #ffffff;
    width: 100px;
    height: 18px;
    position: absolute;
    right: (@triangle-position-right / 2);
    bottom: 0;
  }
}
.narrow-table {
  table& {
    width: auto;
  }
}
