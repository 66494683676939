@link-color: #004dd0;
@light-gray: #fafafa;
@disabled-input: #e6e6e6;
@disabled-input-text: #989898;
@gradient-dark-gray: #e5e5e5;
@gradient-red: #fbe0e1;
@small-difference-bg: linear-gradient(@light-gray, @gradient-dark-gray, @gradient-dark-gray, @light-gray);
@big-difference-bg: linear-gradient(@light-gray, @gradient-red, @gradient-red, @light-gray);
@dark-red: #c20019;
@green: hsl(120 45% 60%);
@orange: #ffa899;

@header-banner-z-index: 5;
@top-bar-z-index: 4;
@mark-pregradings-finished-z-index: 4;
@answer-search-z-index: 3;
@split-screen-gutter-z-index: 3;
@actionbar-z-index: 3;
@answer-and-scores-z-index: 2;
@question-number-row-z-index: 2;
@student-code-z-index: 1;
@score-scroller-z-index: 1;

@header-banner-height: 60px;

.score-difference {
  position: absolute;
  top: 0px;
  right: 0;
  height: 10px;
  font-size: 12px;
  border-radius: 4px;
  line-height: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  user-select: none;
  background: @light-gray;
}

.score-ok {
  .score-difference();
}
