.auto-sa-button {
  .sa-button-auto();
  display: inline-block;
  text-decoration: none;
}

.held-exams-table .link-to-grades.button {
  .sa-button-table();
  text-decoration: none;
}

#page-loading-spinner {
  position: absolute;
  top: 110px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

#tab-bar {
  margin-top: 20px;
  margin-bottom: 60px;

  a {
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
    color: @base-secondary-button-text;
    text-decoration: none;
    padding: 3px 0px;
    margin-right: 10px;
  }
  a:hover {
    color: @text-color-on-highlight;
  }
  .active {
    font-weight: 600;
    border-bottom: 3px solid @base-link-font-blue;
    color: @text-color;
  }
}

.error-notice {
  display: inline;
  margin-left: 15px;
  color: @sa-error-text-color;
}

.active-role-description {
  margin-top: 30px;
  display: inline-block;
}

.role-change {
  display: inline-block;
  margin-left: 15px;

  #show-role-selection {
    .button-as-link();
    font-size: 17px;
  }

  #show-role-selection:disabled {
    color: @base-disabled-text;
    pointer-events: none;
  }

  #role-selection {
    position: absolute;
    z-index: 500;
    margin-top: 5px;
    margin-left: -18px;
    background-color: #fff;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.15);
    font-size: 16px;

    .role {
      padding: 3px 15px;
      cursor: pointer;

      &:first-child {
        padding-top: 5px;
      }
      &:last-child {
        padding-bottom: 5px;
      }
      &:hover {
        background-color: #ededed;
      }
      &.active {
        color: @base-link-font-blue;
      }

      .type {
        text-transform: capitalize;
      }
    }
  }
  .hide-role-selection {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 200;
    background-color: #fff;
    opacity: 0.5;
  }
}

@examTableCellVerticalPadding: 1rem;

#exam-events,
.exam-table-shadow,
#added-teachers,
.registration-table,
.result-lists-table,
.basic-table {
  box-shadow:
    0px 2px 5px -1px rgba(0, 0, 0, 0.1),
    0px 12px 25px -11px rgba(0, 0, 0, 0.05),
    0 0 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow-y: scroll;
  width: 100%;
}
.held-exams-table,
#added-teachers,
.examination-phase-table .registration-table {
  th {
    height: 45px;
  }

  th.header-additional-censor-grading {
    white-space: nowrap;
  }
}

#exam-events,
.exam-table-row {
  margin-bottom: 50px;
}

.held-exams-table {
  width: 100%;
}

#exam-events,
.held-exams-table,
#added-teachers,
.registration-table,
.examination-phase-table,
.result-lists-table {
  font-size: 16px;
  background-color: white;
  th {
    border-bottom: 1px solid #dfdfdf;
    font-size: 16px;
    font-weight: 400;
    padding: 0 @examTableCellVerticalPadding;
    text-align: left;
    line-height: 16px;
    position: sticky;
    z-index: 1;
    top: 0;
    background: #f6f6f6;
    background: hsl(206 28% 96% / 1);
  }
  td {
    padding: 0.5rem @examTableCellVerticalPadding;
    border-bottom: 1px solid #dfdfdf;
  }
  th:first-child,
  td:first-child {
    padding-left: @examTableCellVerticalPadding;
  }
  th:last-child,
  td:last-child {
    padding-right: @examTableCellVerticalPadding;
  }
  th.teachers-ssn {
    width: 180px;
  }
  .exam-error-text {
    color: @sa-error-text-color;
    padding-top: 0px;
    padding-bottom: 7px;
    font-size: 14px;
  }
  .table-section-header {
    height: 50px;
    background-color: #fcfcfc;
    th {
      font-size: 16px;
    }

    &.select-censor,
    &.select-exam {
      height: 30px;
      th {
        height: 30px;
        cursor: pointer;
      }
      th:hover {
        background-color: #fcfcfc;
      }
      a {
        text-decoration: none;
      }
      .censor-code {
        color: @text-color;
      }
    }
  }
  i.fa {
    font-weight: 600;
    position: relative;
    top: 1px;
    margin-right: 5px;
  }
}

#added-teachers {
  .teacher-delete-link {
    display: block;
  }
  .teacher-ssn-wrapper {
    display: flex;
  }
  .edit-teacher {
    display: block;
    flex: 0 0 auto;
    min-width: 26px;
  }
  .teacher-ssn-wrapper > div {
    flex: 1 1 auto;
  }
  input.teacher-name {
    margin-top: -5px;
    width: 100%;
  }

  .teacher-disqualifications,
  .teacher-allowed-exams {
    display: flex;
    flex-direction: column;

    .teacher-disqualifications-input,
    .teacher-allowed-exams-input {
      margin-top: -5px;
    }
    .teacher-disqualifications-submit-wrap,
    .teacher-allowed-exams-submit-wrap {
      height: 37px;
    }
    .teacher-disqualifications-submit,
    .teacher-allowed-exams-submit {
      margin-top: 5px;
      float: right;
    }
    .teacher-disqualifications-list,
    .teacher-allowed-exams-list {
      display: inline-block;
      list-style-type: none;
      padding: 0px;
      margin: 0px;
      margin-bottom: 4px;
    }

    .disqualifications-list-item,
    .allowed-exams-list-item {
      display: flex;
    }
    .disqualifications-value,
    .allowed-exams-value {
      flex: 1 1 auto;
    }
    .disqualifications-list-item a,
    .allowed-exams-list-item a {
      display: block;
      flex: 0 0 auto;
      margin-left: 10px;
    }
  }
}

.exam-server-code-container {
  margin-bottom: 20px;
}

.basic-table {
  width: 100%;
  font-size: 16px;
  background-color: white;
  th {
    border-bottom: 1px solid #dfdfdf;
    font-size: 14px;
    font-weight: 600;
    padding: 6px @examTableCellVerticalPadding;
    text-align: left;
  }
  td {
    padding: 6px @examTableCellVerticalPadding;
    border-bottom: 1px solid #dfdfdf;
    vertical-align: top;
  }
  th:first-child,
  td:first-child {
    padding-left: (2 * @examTableCellVerticalPadding);
  }
  th:last-child,
  td:last-child {
    padding-right: (2 * @examTableCellVerticalPadding);
  }
  thead th {
    height: 35px;
  }
}

.unmapped-students {
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;
}
#exam-events {
  @event-files-col-width: 250px;
  table-layout: fixed;
  td {
    border: 0;
  }
  thead th {
    height: 35px;
    border-bottom: none;
  }

  .exam-event-header-row th {
    border-top: 1px solid #dfdfdf;
    padding-bottom: 0;
    height: 39px;
    border-bottom: none;
  }

  .event-date-col {
    width: 100px;
  }
  .event-files-col {
    width: @event-files-col-width;
  }
  .name {
    color: @text-color;
    text-decoration: none;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 20px;
    padding-top: 5px;
    max-width: 555px;
  }

  button {
    width: calc(@event-files-col-width - 17px);
    padding: 1px 0;
    &:focus {
      outline: 0;
    }
    &:active {
      color: #333333;
      background-color: #ededed;
    }
    &:disabled {
      color: #999999;
      background-color: #f9f9f9;
    }
  }
  .exam-event {
    .no-paper-exam-registrations {
      font-style: italic;
      color: #979797;
      font-size: 90%;
    }
    .exams-list,
    .download-exam {
      padding: 0 10px 10px 10px;
    }
    .exams-row td {
      padding-top: 10px;
    }
    .exams-list .name {
      vertical-align: text-top;
    }
    .download-exam,
    .download-grading-form {
      padding-right: 20px;
      vertical-align: top;
    }
    .no-meb-download-rights {
      font-style: italic;
      color: #979797;
      font-size: 90%;
    }
    .exams-of-event {
      margin: 0;
      padding: 0 0 0 0;
      font-size: 14px;
      .exam {
        list-style-type: none;
        .name {
          padding: 0;
        }
      }
    }
  }
}

.digital-exams-publication-notification {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 7px;
}
#registration-tab {
  h1 {
    margin-bottom: 10px;
  }
  .registrations-upload {
    @media print {
      display: none;
    }
    .registration-not-sent {
      margin-top: 10px;
      margin-bottom: 30px;
    }
    .registration-upload-button-wrapper {
      margin-bottom: 16px;
      position: relative;
      display: flex;
      align-items: center;
      height: 50px;

      .upload-input {
        top: -100000px;
      }
      .upload-button,
      .dry-run-upload-button {
        align-self: center;
        min-width: 300px;
        margin: 0 0;
      }
      .upload-error {
        display: flex;
        align-self: center;
      }
    }
    .upload-takes-long-container {
      margin-left: 10px;
    }
  }

  .dry-run-navi {
    margin-top: -45px;
    padding-bottom: 35px;
    a {
      text-decoration: none;
      color: @base-secondary-button-text;

      &.selected {
        font-weight: bold;
      }
      &:first-child {
        margin-right: 30px;
      }
    }
  }

  .dry-run-results {
    border: 2px dashed @base-brand-blue-border-transparent;
    border-radius: 10px;
    padding: 30px;
  }

  .dry-run-title {
    font-weight: 600;
  }

  .dry-run-uploaded-info {
    font-size: 14px;
    margin-left: 20px;
  }

  .dry-run-no-errors:after {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f00c';
    color: green;
    margin-left: 0.5rem;
  }

  .dry-run-upload-button {
    width: 400px;
  }

  .override-modal {
    color: @text-color;
  }
  .override-button-sidenote {
    font-size: 12px;
    color: @base-disabled-text;
    margin-left: 10px;
  }

  .action-buttons {
    @media print {
      display: none;
    }
  }

  .student-transfer {
    input {
      width: 20em;
      padding: 8px;
    }
    .error {
      color: @base-error-text;
    }
    select {
      background-color: white;
      padding: 6px;
      border: solid lightgray 1px;
      margin-right: 10px;
    }
    @media print {
      display: none;
    }
  }

  .student-transfer {
    margin-left: 5em;
    margin-right: 5em;
  }

  .fa-exclamation-triangle {
    color: @base-error-text;
  }

  .fa-exclamation-circle {
    color: @base-brand-blue;
  }

  .registration-received-notification {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    border: #c8d4db 1px solid;
    border-radius: 3px;
    background-color: #f7fdff;
    color: @base-page-banner-text-color;
    padding: 15px;
    .fa-check-circle {
      color: @base-green-button;
      margin-right: 12px;
    }
  }

  .info_container {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    .unknown_student_name_info {
      font-style: italic;
      font-size: 16px;
      margin-left: 2px;
    }
  }

  .registration-error {
    display: table;
    & > div {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .registry-error-notification,
  .registration-error-notification,
  .registration-warning-notification {
    background-color: @base-error-background;
    color: @base-error-text;
    border: 1px solid lightgrey;
    padding: 14px;
    margin-top: 20px;
    display: flex;
    .fa-exclamation-triangle,
    .fa-exclamation-circle {
      display: flex;
      align-self: center;
    }
    .error-text,
    .warning-text {
      margin-left: 14px;
    }
  }
  .registration-warning-notification {
    color: black;
    background-color: rgb(240, 240, 254);
  }
  .registration-errors,
  .registration-warnings {
    color: @base-error-text;
    padding-right: 85px;
    .separator:not(:last-child) {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid @base-error-text;
      margin: 7px 0;
      padding: 0;
      width: 30px;
    }
    .separator:last-child {
      display: none;
    }
  }
  .registration-warnings {
    color: black;
  }
  .registration-errors-table,
  .registration-warnings-table {
    .fa-exclamation-triangle:after,
    .fa-exclamation-circle:after {
      content: ' ';
      white-space: pre;
    }

    th {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  .accepted-registration-info {
    margin-top: 10px;
  }

  .registration-table {
    margin-top: 20px;
    td {
      vertical-align: top;
    }
    .name-col {
      width: 230px;
      @media print {
        width: 220px;
      }

      .lastname {
        font-weight: bold;
        line-height: 19px;
      }

      .firstname {
        line-height: 19px;
      }
    }
    .pre2022student {
      background-image: url('../public/img/tutkintolaki-pre22-icon.svg');
      background-repeat: no-repeat;
      background-position: left;
      background-size: 50px 24px;
      height: 19px;
    }

    .ssn-col {
      width: 140px;
      @media print {
        width: 110px;
      }
    }
    .name-col.unknown {
      font-style: italic;
    }
  }

  .accepted-registrations-table {
    th {
      vertical-align: bottom;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 3px solid #505050;
      margin-bottom: 16px;
      height: 54px;
      .student-fee-total {
        display: inline-block;
        font-weight: 400;
      }
      .total-amount {
        font-weight: 700;
        text-align: right;
      }
    }

    tbody tr:first-child td {
      padding-top: 0.5rem;
    }

    tbody tr.pre-2022-student td {
      padding-bottom: 0;
      border-bottom: 0 none;
    }

    tr.has-pre2022-student td {
      padding-top: 0;
    }

    .student-fee-col {
      text-align: right;
      font-weight: 700;
    }

    .student-transfer-code {
      position: absolute;
      background-color: white;
      padding: 1em;
      box-shadow: 0px 1px 7px rgb(0 0 0 / 15%);
      width: 70%;
      margin-top: 5px;
      z-index: 1;
      .close {
        background-image: url('../public/img/rasti-icon-small.svg');
        background-repeat: no-repeat;
        width: 18px;
        height: 18px;
        float: right;
        cursor: pointer;
      }
      .code {
        color: @base-brand-blue;
      }
      @media print {
        display: none;
      }
    }

    .invalidated-registration {
      .student-number-col,
      .exams-col,
      .student-kind-col {
        color: #cdcdcd;
      }
    }
    .student-number-col {
      width: 110px;
      @media print {
        width: 90px;
      }
    }
    .exams-col {
      width: 251px;
      @media print {
        width: 190px;
      }
    }
    .student-kind-col {
      width: 95px;
    }
    .exams-table {
      width: 100%;
      .exam-cell {
        border-bottom: 0;
        padding: 0;
      }
    }
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  z-index: 1010;
  background: white;
  overflow-y: auto;
  max-width: 100%;
  max-height: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 80px 40px;

  div {
    padding-bottom: 10px;
  }

  ol {
    padding-left: 15px;
  }

  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  input,
  select,
  label {
    display: block;
  }

  .modal-textarea {
    width: 100%;
    height: 60px;

    background: #ffffff;
    border: 1px solid #6d6d6d;
    box-sizing: border-box;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }

  .education-type-select {
    width: 90%;
    height: 40px;
    background: #ffffff;
    border: 1px solid #6d6d6d;
    box-sizing: border-box;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }

  .modal-text-input {
    width: 100%;
    height: 40px;
    background: #ffffff;
    border: 1px solid #6d6d6d !important;
    box-sizing: border-box;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }

  .invalid-text-input {
    width: 388px;
    height: 40px;
    background: #ffffff;
    border: 1px solid red !important;
    outline-color: red !important;
    box-sizing: border-box;
    border-radius: 3px;
    box-shadow: 0px 1px 4px red;
  }

  .text-block {
    margin-bottom: 20px;
  }

  .confirm-checkbox {
    vertical-align: middle;
    display: inline;
    width: 40px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #6d6d6d;
    box-sizing: border-box;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    margin-right: 5px;
  }

  .confirm-button {
    z-index: 1020;
    margin-right: 20px;
    border: 1px solid #0066cc;
    background: #3b9bb5;
    border-radius: 3px;
    color: #ffffff;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 40px;
    text-align: center;
  }

  .confirm-button-disabled {
    z-index: 1020;
    margin-right: 20px;
    background: #767676 !important;
    border-radius: 3px;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 40px;
    text-align: center;
    color: #ffffff !important;
  }

  .cancel-button {
    z-index: 1020;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 40px;
    text-align: center;
    color: #359bb7;
    box-shadow: none;
    border: none;
  }
}

.display-block {
  display: flex;
}

.display-none {
  display: none;
}

.euro-sign {
  display: inline-block;
  width: 19px;
  height: 19px;
  background: url('../public/img/euro-sign_euro.svg') no-repeat center;
  position: relative;
  top: 4px;
}

#examination-phase-tab {
  h1 {
    margin-bottom: 10px;
  }

  .fa-exclamation-triangle {
    color: @base-error-text;
  }

  .fa-exclamation-circle {
    color: @base-brand-blue;
  }

  .student-errors {
    color: @base-error-text;
    padding-right: 85px;
    .separator:not(:last-child) {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid @base-error-text;
      margin: 7px 0;
      padding: 0;
      width: 30px;
    }
    .separator:last-child {
      display: none;
    }
  }
  .student-info {
    margin-top: 10px;
  }

  .examination-phase-table {
    background-color: inherit;
    margin-top: 20px;

    .student-exam-details-table {
      width: 100%;
      margin-left: -10px;

      tbody tr:first-child td {
        padding-top: 0;
      }

      tbody tr:last-child td {
        padding-bottom: 16px;
      }

      td {
        padding-left: 0px;
        padding-top: 0px;
        border-bottom: none;
      }

      td:last-child {
        padding-right: 0px;
      }

      tr:last-child {
        border-bottom: 0;
      }

      .failed-subject {
        color: @base-disabled-text;
      }

      .retry-attempt {
        text-align: right;
      }

      .grade {
        width: 5%;
      }

      .subject {
        width: 30%;
      }

      .reg-code {
        width: 5%;
      }
    }

    .student-exam-details-table-border:not(:last-child) {
      border-bottom: 1px dashed #d3d3d3;
    }

    th {
      vertical-align: bottom;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 3px solid #505050;
      background-color: #fafafa;
      margin-bottom: 16px;
      height: 54px;
    }

    tbody tr:first-child td {
      padding-top: 0.5rem;
    }

    th:first-child,
    td:first-child {
      padding-left: @examTableCellVerticalPadding;
    }

    tbody tr.pre-2022-student td {
      padding-bottom: 0;
      border-bottom: 0 none;
    }

    td {
      vertical-align: middle;
    }

    tr:last-child {
      border-bottom: 3px solid #505050;
    }

    .student-kind-col {
      width: 95px;
    }

    .name-col {
      width: 200px;
      @media print {
        width: 170px;
      }

      .lastname {
        font-weight: bold;
        line-height: 19px;
      }

      .firstname {
        line-height: 19px;
      }
    }

    .has-necessary-exams-col {
      width: 310px;
      ul {
        margin-bottom: 0px;
        margin-top: 0px;
        list-style: none;
        padding: 0;
      }
    }

    .has-necessary-exams-col-incomplete {
      li:before {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: '\f00d';
        color: orange;
        margin-right: 0.5rem;
      }
    }

    .has-necessary-exams-col-complete {
      li:before {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: '\f00c';
        color: green;
        margin-right: 0.5rem;
      }
    }

    .student-kind-col {
      width: 95px;
    }
    .restart-col {
      text-align: left;
    }

    .graduation-notice-col {
      width: 210px;
      @media print {
        width: 180px;
      }
    }

    .graduation-notice-btn {
      padding: 0;
      border: none;
      background: none;
      outline: none;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #18bc9c;
      box-shadow: none;
    }

    .ssn-col {
      width: 130px;
      @media print {
        width: 110px;
      }
    }

    .expanded-row-header {
      margin: 0;
    }

    .expanded-row-title {
      margin-top: 1.5rem;
      margin-bottom: 0px;
      margin-left: 5px;
      font-weight: bold;
    }

    .expanded-row-title-last-round,
    .expanded-row-title-free-exams-left {
      margin-bottom: 0px;
      margin-left: 5px;
    }

    .rejected-examination {
      color: @base-disabled-text;

      & h1 {
        color: @base-disabled-text;
      }
    }

    .examination-status-title {
      color: @base-disabled-text;
      float: right;
      padding-right: 4px;
      & h2,
      h3,
      h4 {
        margin-top: 0px;
        margin-bottom: 0px;
      }
    }

    .included-exams-title {
      margin-top: 15px;
      margin-left: 5px;
    }

    .expanded-row-table-examinationcode {
      padding-left: 5px;
      margin-bottom: 10px;
    }

    .extratime-period {
      color: @base-disabled-text;
    }

    .pre2022student {
      background-image: url('../public/img/tutkintolaki-pre22-icon.svg');
      background-repeat: no-repeat;
      background-position: left;
      background-size: 50px 24px;
      height: 19px;
    }

    .expand-link {
      font-size: 30px;
    }

    .expanded-examination-phase-table {
      font-size: 30px;
      border: 0;
    }

    .expanded-table-row {
      width: 100%;
      background: linear-gradient(#e4e4e4, #f4f4f4);
    }

    th.show-examination-history {
      width: 150px;
      text-align: right;
    }

    td.show-examination-history {
      background-color: #e4e4e4;
      width: 150px;
      text-align: center;
      padding: 0.5rem;
    }

    .name-col.unknown {
      font-style: italic;
    }
  }
}

#exam-results-tab {
  .info-text {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 7px;
  }
  .download-button-cell {
    text-align: right;
  }
}

#answers-upload-panel {
  .import-answers-instructions {
    margin-top: 0;
  }
  .answers-upload-button-wrapper {
    position: relative;
    display: inline-block;
  }
}

.answers-uploaded-notification {
  .notification();
  display: none;
  margin-bottom: 30px;
  padding: 12px 20px 20px;
  line-height: 20px;

  #answers-uploaded-close {
    .sa-button-table();
    float: right;
    padding: 1px 20px;
    min-width: 100px;
  }

  .clear-both {
    clear: both;
  }

  .upload-notification-title {
    .header3();
    padding-bottom: 3px;
  }

  .school-title > td {
    padding: 15px 0 5px;
  }

  .without-school {
    padding-bottom: 3px;
  }

  td.answer-count {
    text-align: right;
    vertical-align: top;
  }

  td.exam-title {
    padding-right: 35px;
  }
}

.upload-spinner {
  position: absolute;
  top: 20px;
  left: 240px;
}

.upload-input {
  visibility: hidden;
  position: absolute;
}

.upload-takes-long-container {
  display: inline-block;
  position: relative;
  .notification();
  .notification-text {
    margin-left: 5px;
  }
  .upload-spinner {
    position: absolute;
    top: 18px;
    left: 18px;
  }
}

.held-exams-table.is_censor {
  .for_in-pregrading {
    display: none !important;
  }
  .for_grading-active {
    display: none !important;
  }
  .for_grading-finished {
    display: none !important;
  }

  .is_in-pregrading .for_in-pregrading {
    display: inline-block !important;
  }
  .is_grading-active .for_grading-active {
    display: inline-block !important;
  }
  .is_grading-finished .for_grading-finished {
    display: inline-block !important;
  }
  .is_grading-finished .for_grading-finished.grading-progress,
  .is_grading-active .for_grading-active.grading-progress {
    display: flex !important;
  }

  td:nth-child(8) {
    text-align: left;
  }
  td:last-child {
    width: 55px;
  }
}

.held-exams-table.is_pregrading {
  td.progress {
    padding: 1rem 0;
  }
  .for_grading-active {
    display: none !important;
  }
  .for_grading-finished {
    display: none !important;
  }

  .is_grading-active .for_grading-active {
    display: inline-block !important;
    margin-right: auto;
    margin-left: auto;
  }
  .is_grading-finished .for_grading-finished {
    display: inline-block !important;
    margin-right: auto;
    margin-left: auto;
  }
}

.held-exams-table.is_collapsed {
  .for_expanded {
    display: none;
  }
}

.held-exams-table.is_expanded {
  .for_collapsed {
    display: none;
  }
}

tr.disabled {
  * {
    color: black;
  }
  & > * {
    opacity: 0.25;
  }

  td {
    &.work-left {
      &.late {
        animation: none;
      }
      div.late  {
        display: none;
      }
    }
  }
}

.held-exams-table {
  th {
    .pregrading {
      .before {
        display: inline-block;
        content: '';
        width: 10px;
        height: 10px;
        margin-right: 5px;
        border-radius: 10px;
      }

      &_graded:before {
        .before;
        background: @bar-graded;
      }

      &_finished:before {
        .before;
        background: @bar-finished;
      }
    }
  }
  td {
    position: relative;
    line-height: 18px;
    vertical-align: middle;

    &.work-left {
      font-size: 14px;

      b {
        font-size: 16px;
      }

      .target-date {
        display: flex;
        gap: 0.5rem;
        white-space: nowrap;
        font-weight: 700;
      }

      .date {
        width: 60px;
        font-size: 16px;
      }

      .late {
        padding: 0 5px;
        background: #ea0b0b;
        color: white;
        font-weight: 700;
        display: inline-block;
        border-radius: 4px;
        margin-top: 1px;
      }

      .green {
        color: #00b15c;
        font-weight: 600;
      }
    }
  }

  .header {
    &-school-exam-anon-code,
    &-school-number {
      width: 85px;
    }

    &-school-name {
      width: auto;
    }

    &-index,
    &-held-answers,
    &-students {
      width: 55px;
    }

    &-additional-censor-grading {
      text-align: left;
    }

    &-link-to-grades {
      font-size: 14px;
      text-align: center;
    }
  }

  td .exam-with-no-grader {
    color: red;
    white-space: nowrap;
  }

  td.productive-progress {
    width: auto;

    .grading-progress {
      justify-content: flex-start;
    }

    .grading-completed {
      width: auto;
    }
  }

  .grading-completed {
    font-weight: 400;
    text-align: center;
  }

  .total-progress {
    font-weight: 400;

    .progress-bg {
      display: inline-block;
      margin-left: 8px;
      position: relative;
      top: 1px;
    }
    .separate {
      margin: 0 5px;
    }
  }
}

#first-level-censoring .held-exams-table.paper-exam,
.next-level-censoring.held-exams-table .exam-row {
  .first-level-censoring-status {
    width: 88px;
    text-align: center;
  }
  .to-grading {
    width: 100px;
  }

  .button {
    .sa-button-table();
    display: inline-block;
    text-decoration: none;
  }
}

.exam-title-collapse-link-text {
  color: @text-color;
}

.exam-title-collapse-link-content {
  display: inline-flex;
  align-items: center;
  color: @text-color;

  .details {
    min-width: 120px;
    flex: 0 1 auto;
  }

  .progress {
    min-width: 100px;
    flex: 0 1 auto;
  }

  .progress-bg {
    display: inline-flex;
  }

  .progress-text {
    width: auto;
  }

  .name {
    margin-left: 20px;
    flex: 0 1 auto;
  }
}

.next-level-censoring-tables,
.grading-inspection-tables {
  display: table;
  width: 100%;

  .next-level-censoring tbody {
    .button {
      .sa-button-table();
      display: inline-block;
      text-decoration: none;
    }
    .completed {
      color: #999;
    }
  }
}

.grading-inspection-tables {
  td:nth-child(1) {
    text-align: left;
    width: 82%;
  }
  td:nth-child(2) {
    text-align: left;
    width: 18%;
  }
  td:last-child {
    text-align: right;
    width: 10%;
  }
  .link-to-inspection.button {
    .sa-button-table();
    display: inline-block;
    text-decoration: none;
  }
  .empty,
  .loading {
    text-align: center !important;
  }
}

.next-level-censoring-tables {
  .first-level-censor {
    text-align: left;
  }
  .next-level-censoring {
    border-top: none;
  }
  .held-exams-table {
    box-shadow: none;
  }
  .link-to-grades {
    display: inline-block;
  }
  .next-level-censoring-info {
    font-size: 16px;
    text-align: left;
  }
}

.fetch-exams-spinner-row {
  height: 38px;
  display: none;
}

#exam-teachers-tab {
  .teachers-description {
    margin-top: 0;
    margin-bottom: 30px;
  }
  .io-error {
    position: fixed;
    top: 0;
    background: #5a99b3;
    width: 100%;
    left: 0;
    height: 62px;
    text-align: center;
    line-height: 62px;
    color: white;
    cursor: pointer;
  }
}

#credentials-tab {
  display: flex;
  gap: 3rem;
  flex-direction: column;

  & > div {
    width: 50%;
  }

  .api-key-container {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    margin: 1rem 0;
    padding: 0.5rem;
    background: hsl(218deg 61% 88%);
    border-radius: 3px;

    input {
      border: 4px solid #fff;
      border-radius: 3px;
    }
  }
  .copied {
    color: #049c69;
    position: absolute;
    margin-top: 1.7rem;
    margin-left: 1rem;
  }
  .missing {
    color: hsl(8, 85%, 47%);
    margin-top: 1rem;
  }
  .api-key {
    font-family: 'Courier New', Courier, 'Lucida Sans Typewriter', 'Lucida Typewriter', monospace;
  }
}

#eula-show-current-button {
  .button-as-link();
  font-size: 17px;
  margin-left: 2rem;
  float: right;
  margin-top: 33px;
}

.censor-payments-link {
  display: block;
  float: right;
  margin-top: 33px;
  line-height: 32px;
}

.toggle-enter {
  opacity: 0;
  transform: scale(0.9);
}
.toggle-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition:
    opacity 200ms,
    transform 200ms;
}
.toggle-exit {
  opacity: 1;
}
.toggle-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition:
    opacity 200ms,
    transform 200ms;
}

#eula-dialog-base {
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  margin: 30px auto 30px auto;
  padding: 60px 80px 20px 80px;

  .header {
    .header2();
  }

  #eula-display-container {
    font-weight: 600;
    display: flex;
    justify-content: space-between;

    button {
      width: 260px;
    }
  }

  #eula-agreement-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    [for='eula-agreement-checkbox'] {
      display: flex;
      justify-content: space-between;
    }
    .eula-checkbox-container {
      div {
        font-weight: 600;
      }

      input[type='checkbox'] {
        display: none;
      }
      label {
        cursor: pointer;
      }
      input[type='checkbox'] + label .eula-custom-checkbox {
        width: 30px;
        min-width: 30px;
        margin-right: 25px;
        height: 30px;
        display: block;
        border: 2px solid #aaaaaa;
        border-radius: 5px;
        background-color: #fafafa;
        position: relative;
        top: 25px;
        cursor: pointer;
      }

      input[type='checkbox']:checked + label .eula-custom-checkbox {
        background: #fafafa url('../public/img/check-mark.svg') no-repeat center;
      }
    }
  }
}

.text-muted {
  opacity: 0.5;
}

dl {
  display: grid;
  grid-template-columns: auto auto;
}

dt {
  grid-column: 1;
}

dd {
  grid-column: 2;
  white-space: nowrap;
}

.js-invoice-table {
  td {
    text-align: right;
  }
}

@media print {
  #pagebanner {
    display: none !important;
  }

  #tab-bar {
    display: none;
  }
}
