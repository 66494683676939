@import '~@digabi/exam-engine-core/dist/main.css';
@import '../node_modules/normalize.css/normalize.css';
@import '../node_modules/source-sans/source-sans-3.css';
@import '../node_modules/@fortawesome/fontawesome-free/css/all.css';
@import '../node_modules/spin.js/spin.css';
/****************************
 Common fonts, sizes, colors
*****************************/
/******************************
   Default Style definitions
*******************************/
*,
*:before,
*:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
body {
  background: #fafafa;
  font-family: 'Source Sans 3', sans-serif;
  font-size: 18px;
  line-height: 25px;
  color: #263d43;
  margin: 0;
}
.hidden {
  display: none !important;
}
h1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  color: #505050;
  margin: 50px 0 10px;
}
h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  color: #505050;
  margin: 10px 0 10px;
}
h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  margin: 20px 0;
}
h4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 20px 0;
}
p {
  margin: 20px 0;
}
strong {
  font-weight: 700;
}
input:not([type='file']):not([type='radio']),
textarea {
  background: white;
  border: 2px solid #e6e6e6;
  padding: 3px;
  font-family: 'Source Sans 3', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #263d43;
}
input:not([type='file']):not([type='radio']):focus,
textarea:focus,
input:not([type='file']):not([type='radio']):required:focus,
textarea:required:focus {
  box-shadow: 0 0 5px rgba(0, 110, 210, 0.4) !important;
  outline: none;
  border-color: rgba(0, 110, 210, 0.7) !important;
}
textarea {
  display: block;
  resize: vertical;
}
input:disabled,
textarea:disabled {
  border-color: #d6d6d6;
  background-color: #f9f9f9;
}
a {
  color: #004DD0;
  cursor: pointer;
  text-decoration: underline;
}
a:hover {
  color: #032E78;
}
a.disabled {
  color: #c8c8c8;
  pointer-events: none;
}
table {
  border-collapse: collapse;
}
table button {
  font-family: 'Source Sans 3', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  background: #fefefe;
  color: #6d6d6d;
  border: 1px #c8c8c8 solid;
  box-shadow: 0 0 3px #ddd;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  border-radius: 2px;
  margin: 0;
  padding: 1px 20px;
  width: auto;
  height: auto;
  white-space: nowrap;
}
table button[disabled],
table button.disabled {
  border-color: #dfdfdf;
  color: #dfdfdf;
  background-color: #fefefe;
  box-shadow: none;
}
table button[disabled]:hover,
table button.disabled:hover {
  border-color: #dfdfdf;
  color: #dfdfdf;
  background-color: #fefefe;
  box-shadow: none;
}
table button:hover {
  background-color: #f8f8f8;
  color: #333333;
}
/******************************
   Common SA styles
*******************************/
.content {
  max-width: 1500px;
  padding: 0;
  margin: 80px auto 80px;
}
.disabled {
  color: #c8c8c8;
}
.disabled * {
  color: #c8c8c8;
}
.goBack {
  position: relative;
  color: #004DD0;
  display: inline-block;
}
.goBack a {
  font-family: 'Source Sans 3', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-decoration: underline;
  color: #004DD0;
  position: relative;
  display: inline-block;
  left: 25px;
}
.goBack a:before {
  display: inline-block;
  position: absolute;
  left: -25px;
  font-size: 32px;
  line-height: 32px;
  text-decoration: none;
  content: '\2190';
  font-weight: 400;
  overflow: hidden;
  height: 28px;
}
.goBack a:before {
  font-weight: 400;
}
.goBack a:before {
  top: -1px;
}
.goBack a i {
  padding-right: 6px;
}
.goBack a:hover {
  color: #032E78;
}
.goBack a.disabled {
  color: #c8c8c8;
  pointer-events: none;
}
button {
  font-family: 'Source Sans 3', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  background: #004DD0;
  color: white;
  padding: 0.25rem 1rem;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
  width: auto;
}
button:hover {
  background-color: #032E78;
}
button:hover {
  background: #032E78;
  color: white;
}
button:active {
  box-shadow: none;
}
.button {
  display: inline-block;
  text-decoration: none;
  font-family: 'Source Sans 3', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  background: #004DD0;
  color: white;
  padding: 0.25rem 1rem;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
  width: auto;
}
.button:hover {
  background-color: #032E78;
}
.button:hover {
  background: #032E78;
  color: white;
}
.button:active {
  box-shadow: none;
}
.disabled button,
button:disabled,
.disabled button:hover,
button:disabled:hover {
  background: #c8c8c8;
  cursor: default;
  color: white;
  box-shadow: none;
}
#floating-error {
  font-weight: 400;
  font-size: 18px;
  color: #c20019;
  background: #fff;
  box-shadow: 0 2px 3px #ddd;
  line-height: 37px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-height: 60px;
  z-index: 1000;
  padding-left: 40px;
  padding-right: 40px;
}
#floating-error .error-symbol {
  color: #9b0000;
  font-size: 32px;
  position: absolute;
  top: 20px;
}
#floating-error .message {
  padding-top: 60px;
  padding-bottom: 20px;
  margin: 0 auto;
  max-width: 640px;
  color: #263d43;
}
#floating-error button {
  width: unset;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 40px;
}
#floating-error p {
  margin: 0;
}
#disabled-background {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  opacity: 0.6;
  height: 100%;
  width: 100%;
}
/************************
    Component Mixins
*************************/
.sa-secondary-button {
  background: #fefefe;
  color: #6d6d6d;
  border: 1px #c8c8c8 solid;
  box-shadow: 0 0 3px #ddd;
}
.sa-secondary-button[disabled],
.sa-secondary-button.disabled {
  border-color: #dfdfdf;
  color: #dfdfdf;
  background-color: #fefefe;
  box-shadow: none;
}
.sa-secondary-button[disabled]:hover,
.sa-secondary-button.disabled:hover {
  border-color: #dfdfdf;
  color: #dfdfdf;
  background-color: #fefefe;
  box-shadow: none;
}
.sa-secondary-button:hover {
  background-color: #f8f8f8;
  color: #333333;
}
i.fa-times-circle {
  position: relative;
  top: 0;
  padding-right: 1px;
}
table.narrow-table {
  width: auto;
}
/**********************
  Yo colors
***********************/
body {
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: auto;
}
#pagebanner {
  width: 100%;
  background: white;
  border-bottom: 1px solid #c8c8c8;
}
#pagebanner .banner-content {
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  max-width: 1500px;
  margin: 0 auto;
  padding-left: 12px;
  padding-right: 12px;
  color: #555555;
  font-size: 14px;
}
#pagebanner .banner-content .banner-left {
  background: url('../public/img/pallasathene-icon-header.png') no-repeat left center;
  padding-left: 70px;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
#pagebanner .banner-content .banner-left span {
  color: #555555;
  font-size: 20px;
  font-weight: 400;
}
#pagebanner .banner-content .user-area {
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
#page-content {
  margin-top: 16px;
  padding-left: 12px;
  padding-right: 12px;
}
.login-notification {
  color: #c20019;
  display: none;
}
#vetuma-login h3 {
  margin-bottom: 0px;
}
#vetuma-login .login-method-list {
  list-style-position: inside;
  padding-left: 0px;
  margin-top: 8px;
  margin-bottom: 24px;
}
#vetuma-login .login {
  width: auto;
  padding-left: 50px;
  padding-right: 50px;
}
.back-to-login {
  width: auto;
  padding-left: 50px;
  padding-right: 50px;
}
.login-error {
  display: none;
  margin-bottom: 24px;
  text-align: center;
  color: #c20019;
  background-color: rgba(240, 60, 60, 0.2);
  margin-left: 420px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.add-teacher-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}
.add-teacher-container label {
  margin-right: 16px;
}
.add-teacher-container label span {
  font-size: 16px;
  font-weight: 600;
  display: block;
}
.add-teacher-container input.teacher-ssn,
.add-teacher-container input.teacher-name {
  padding-left: 12px;
  padding-right: 12px;
  height: 40px;
  line-height: 40px;
  width: 220px;
}
.add-teacher-container button {
  margin: 0;
  margin-top: 24px;
}
.add-teacher-container .loader {
  height: 40px;
  font-height: 13px;
  display: flex;
  margin-left: 10px;
  align-items: center;
}
.add-teacher-container .teacher-new-allowed-exams {
  display: flex;
  flex-direction: column;
}
.add-teacher-container .teacher-new-allowed-exams .teacher-new-allowed-exams-input {
  margin-top: -5px;
  height: 40px;
  width: 250px;
}
.add-teacher-container .teacher-new-allowed-exams .teacher-new-allowed-exams-submit-wrap {
  height: 37px;
}
.add-teacher-container .teacher-new-allowed-exams .teacher-new-allowed-exams-submit {
  margin-top: 5px;
  float: right;
}
.add-teacher-container .teacher-new-allowed-exams .teacher-new-allowed-exams-list {
  display: inline-block;
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  margin-bottom: 4px;
}
.add-teacher-container .teacher-new-allowed-exams .new-allowed-exams-list-item {
  display: flex;
}
.add-teacher-container .teacher-new-allowed-exams .new-allowed-exams-value {
  flex: 1 1 auto;
}
.add-teacher-container .teacher-new-allowed-exams .new-allowed-exams-list-item a {
  display: block;
  flex: 0 0 auto;
  margin-left: 10px;
}
#added-teachers td {
  vertical-align: top;
}
#added-teachers th:nth-child(2) {
  width: 300px;
}
#added-teachers th:nth-child(3) {
  min-width: 300px;
}
#added-teachers th:nth-child(4) {
  min-width: 300px;
}
#added-teachers .no-teacher-name {
  font-style: italic;
  color: #6d6d6d;
}
#no-teachers {
  font-style: italic;
  color: #6d6d6d;
}
.input-error {
  background-color: #fee9ea !important;
}
#add-teacher-validation-error {
  position: absolute;
  min-width: 300px;
  margin-left: -40px;
  margin-top: 12px;
  z-index: 100;
  vertical-align: top;
  background: #fee9ea;
  padding: 10px 15px;
  color: #c20019;
  font-size: 14px;
  text-align: center;
  border: 1px solid #c20019;
  border-radius: 3px;
}
#add-teacher-validation-error .hidden {
  display: block;
}
#add-teacher-validation-error:before {
  content: '';
  width: 11px;
  height: 11px;
  background-color: #fee9ea;
  border: 1px solid #c20019;
  position: absolute;
  top: -6px;
  left: 144.5px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
#add-teacher-validation-error:after {
  content: '';
  background-color: #fee9ea;
  width: 100px;
  height: 15px;
  position: absolute;
  left: 100px;
  top: 0;
}
.auto-sa-button {
  font-family: 'Source Sans 3', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  background: #004DD0;
  color: white;
  padding: 0.25rem 1rem;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
  width: auto;
  display: inline-block;
  text-decoration: none;
}
.auto-sa-button:hover {
  background-color: #032E78;
}
.auto-sa-button:hover {
  background: #032E78;
  color: white;
}
.auto-sa-button:active {
  box-shadow: none;
}
.held-exams-table .link-to-grades.button {
  font-family: 'Source Sans 3', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  background: #fefefe;
  color: #6d6d6d;
  border: 1px #c8c8c8 solid;
  box-shadow: 0 0 3px #ddd;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  border-radius: 2px;
  margin: 0;
  padding: 1px 20px;
  width: auto;
  height: auto;
  white-space: nowrap;
  text-decoration: none;
}
.held-exams-table .link-to-grades.button[disabled],
.held-exams-table .link-to-grades.button.disabled {
  border-color: #dfdfdf;
  color: #dfdfdf;
  background-color: #fefefe;
  box-shadow: none;
}
.held-exams-table .link-to-grades.button[disabled]:hover,
.held-exams-table .link-to-grades.button.disabled:hover {
  border-color: #dfdfdf;
  color: #dfdfdf;
  background-color: #fefefe;
  box-shadow: none;
}
.held-exams-table .link-to-grades.button:hover {
  background-color: #f8f8f8;
  color: #333333;
}
#page-loading-spinner {
  position: absolute;
  top: 110px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}
#tab-bar {
  margin-top: 20px;
  margin-bottom: 60px;
}
#tab-bar a {
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  color: #6d6d6d;
  text-decoration: none;
  padding: 3px 0px;
  margin-right: 10px;
}
#tab-bar a:hover {
  color: #000;
}
#tab-bar .active {
  font-weight: 600;
  border-bottom: 3px solid #004DD0;
  color: #263d43;
}
.error-notice {
  display: inline;
  margin-left: 15px;
  color: #c20019;
}
.active-role-description {
  margin-top: 30px;
  display: inline-block;
}
.role-change {
  display: inline-block;
  margin-left: 15px;
}
.role-change #show-role-selection {
  font-family: 'Source Sans 3', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-decoration: underline;
  color: #004DD0;
  width: auto;
  width: initial;
  height: auto;
  height: initial;
  text-align: left;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  box-shadow: none;
  outline: none;
  font-size: 17px;
}
.role-change #show-role-selection i {
  padding-right: 6px;
}
.role-change #show-role-selection:hover {
  color: #032E78;
}
.role-change #show-role-selection:disabled {
  color: #6d6d6d;
  pointer-events: none;
}
.role-change #role-selection {
  position: absolute;
  z-index: 500;
  margin-top: 5px;
  margin-left: -18px;
  background-color: #fff;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.15);
  font-size: 16px;
}
.role-change #role-selection .role {
  padding: 3px 15px;
  cursor: pointer;
}
.role-change #role-selection .role:first-child {
  padding-top: 5px;
}
.role-change #role-selection .role:last-child {
  padding-bottom: 5px;
}
.role-change #role-selection .role:hover {
  background-color: #ededed;
}
.role-change #role-selection .role.active {
  color: #004DD0;
}
.role-change #role-selection .role .type {
  text-transform: capitalize;
}
.role-change .hide-role-selection {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  background-color: #fff;
  opacity: 0.5;
}
#exam-events,
.exam-table-shadow,
#added-teachers,
.registration-table,
.result-lists-table,
.basic-table {
  box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.1), 0px 12px 25px -11px rgba(0, 0, 0, 0.05), 0 0 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow-y: scroll;
  width: 100%;
}
.held-exams-table th,
#added-teachers th,
.examination-phase-table .registration-table th {
  height: 45px;
}
.held-exams-table th.header-additional-censor-grading,
#added-teachers th.header-additional-censor-grading,
.examination-phase-table .registration-table th.header-additional-censor-grading {
  white-space: nowrap;
}
#exam-events,
.exam-table-row {
  margin-bottom: 50px;
}
.held-exams-table {
  width: 100%;
}
#exam-events,
.held-exams-table,
#added-teachers,
.registration-table,
.examination-phase-table,
.result-lists-table {
  font-size: 16px;
  background-color: white;
}
#exam-events th,
.held-exams-table th,
#added-teachers th,
.registration-table th,
.examination-phase-table th,
.result-lists-table th {
  border-bottom: 1px solid #dfdfdf;
  font-size: 16px;
  font-weight: 400;
  padding: 0 1rem;
  text-align: left;
  line-height: 16px;
  position: sticky;
  z-index: 1;
  top: 0;
  background: #f6f6f6;
  background: hsl(206, 28%, 96%);
}
#exam-events td,
.held-exams-table td,
#added-teachers td,
.registration-table td,
.examination-phase-table td,
.result-lists-table td {
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #dfdfdf;
}
#exam-events th:first-child,
.held-exams-table th:first-child,
#added-teachers th:first-child,
.registration-table th:first-child,
.examination-phase-table th:first-child,
.result-lists-table th:first-child,
#exam-events td:first-child,
.held-exams-table td:first-child,
#added-teachers td:first-child,
.registration-table td:first-child,
.examination-phase-table td:first-child,
.result-lists-table td:first-child {
  padding-left: 1rem;
}
#exam-events th:last-child,
.held-exams-table th:last-child,
#added-teachers th:last-child,
.registration-table th:last-child,
.examination-phase-table th:last-child,
.result-lists-table th:last-child,
#exam-events td:last-child,
.held-exams-table td:last-child,
#added-teachers td:last-child,
.registration-table td:last-child,
.examination-phase-table td:last-child,
.result-lists-table td:last-child {
  padding-right: 1rem;
}
#exam-events th.teachers-ssn,
.held-exams-table th.teachers-ssn,
#added-teachers th.teachers-ssn,
.registration-table th.teachers-ssn,
.examination-phase-table th.teachers-ssn,
.result-lists-table th.teachers-ssn {
  width: 180px;
}
#exam-events .exam-error-text,
.held-exams-table .exam-error-text,
#added-teachers .exam-error-text,
.registration-table .exam-error-text,
.examination-phase-table .exam-error-text,
.result-lists-table .exam-error-text {
  color: #c20019;
  padding-top: 0px;
  padding-bottom: 7px;
  font-size: 14px;
}
#exam-events .table-section-header,
.held-exams-table .table-section-header,
#added-teachers .table-section-header,
.registration-table .table-section-header,
.examination-phase-table .table-section-header,
.result-lists-table .table-section-header {
  height: 50px;
  background-color: #fcfcfc;
}
#exam-events .table-section-header th,
.held-exams-table .table-section-header th,
#added-teachers .table-section-header th,
.registration-table .table-section-header th,
.examination-phase-table .table-section-header th,
.result-lists-table .table-section-header th {
  font-size: 16px;
}
#exam-events .table-section-header.select-censor,
.held-exams-table .table-section-header.select-censor,
#added-teachers .table-section-header.select-censor,
.registration-table .table-section-header.select-censor,
.examination-phase-table .table-section-header.select-censor,
.result-lists-table .table-section-header.select-censor,
#exam-events .table-section-header.select-exam,
.held-exams-table .table-section-header.select-exam,
#added-teachers .table-section-header.select-exam,
.registration-table .table-section-header.select-exam,
.examination-phase-table .table-section-header.select-exam,
.result-lists-table .table-section-header.select-exam {
  height: 30px;
}
#exam-events .table-section-header.select-censor th,
.held-exams-table .table-section-header.select-censor th,
#added-teachers .table-section-header.select-censor th,
.registration-table .table-section-header.select-censor th,
.examination-phase-table .table-section-header.select-censor th,
.result-lists-table .table-section-header.select-censor th,
#exam-events .table-section-header.select-exam th,
.held-exams-table .table-section-header.select-exam th,
#added-teachers .table-section-header.select-exam th,
.registration-table .table-section-header.select-exam th,
.examination-phase-table .table-section-header.select-exam th,
.result-lists-table .table-section-header.select-exam th {
  height: 30px;
  cursor: pointer;
}
#exam-events .table-section-header.select-censor th:hover,
.held-exams-table .table-section-header.select-censor th:hover,
#added-teachers .table-section-header.select-censor th:hover,
.registration-table .table-section-header.select-censor th:hover,
.examination-phase-table .table-section-header.select-censor th:hover,
.result-lists-table .table-section-header.select-censor th:hover,
#exam-events .table-section-header.select-exam th:hover,
.held-exams-table .table-section-header.select-exam th:hover,
#added-teachers .table-section-header.select-exam th:hover,
.registration-table .table-section-header.select-exam th:hover,
.examination-phase-table .table-section-header.select-exam th:hover,
.result-lists-table .table-section-header.select-exam th:hover {
  background-color: #fcfcfc;
}
#exam-events .table-section-header.select-censor a,
.held-exams-table .table-section-header.select-censor a,
#added-teachers .table-section-header.select-censor a,
.registration-table .table-section-header.select-censor a,
.examination-phase-table .table-section-header.select-censor a,
.result-lists-table .table-section-header.select-censor a,
#exam-events .table-section-header.select-exam a,
.held-exams-table .table-section-header.select-exam a,
#added-teachers .table-section-header.select-exam a,
.registration-table .table-section-header.select-exam a,
.examination-phase-table .table-section-header.select-exam a,
.result-lists-table .table-section-header.select-exam a {
  text-decoration: none;
}
#exam-events .table-section-header.select-censor .censor-code,
.held-exams-table .table-section-header.select-censor .censor-code,
#added-teachers .table-section-header.select-censor .censor-code,
.registration-table .table-section-header.select-censor .censor-code,
.examination-phase-table .table-section-header.select-censor .censor-code,
.result-lists-table .table-section-header.select-censor .censor-code,
#exam-events .table-section-header.select-exam .censor-code,
.held-exams-table .table-section-header.select-exam .censor-code,
#added-teachers .table-section-header.select-exam .censor-code,
.registration-table .table-section-header.select-exam .censor-code,
.examination-phase-table .table-section-header.select-exam .censor-code,
.result-lists-table .table-section-header.select-exam .censor-code {
  color: #263d43;
}
#exam-events i.fa,
.held-exams-table i.fa,
#added-teachers i.fa,
.registration-table i.fa,
.examination-phase-table i.fa,
.result-lists-table i.fa {
  font-weight: 600;
  position: relative;
  top: 1px;
  margin-right: 5px;
}
#added-teachers .teacher-delete-link {
  display: block;
}
#added-teachers .teacher-ssn-wrapper {
  display: flex;
}
#added-teachers .edit-teacher {
  display: block;
  flex: 0 0 auto;
  min-width: 26px;
}
#added-teachers .teacher-ssn-wrapper > div {
  flex: 1 1 auto;
}
#added-teachers input.teacher-name {
  margin-top: -5px;
  width: 100%;
}
#added-teachers .teacher-disqualifications,
#added-teachers .teacher-allowed-exams {
  display: flex;
  flex-direction: column;
}
#added-teachers .teacher-disqualifications .teacher-disqualifications-input,
#added-teachers .teacher-allowed-exams .teacher-disqualifications-input,
#added-teachers .teacher-disqualifications .teacher-allowed-exams-input,
#added-teachers .teacher-allowed-exams .teacher-allowed-exams-input {
  margin-top: -5px;
}
#added-teachers .teacher-disqualifications .teacher-disqualifications-submit-wrap,
#added-teachers .teacher-allowed-exams .teacher-disqualifications-submit-wrap,
#added-teachers .teacher-disqualifications .teacher-allowed-exams-submit-wrap,
#added-teachers .teacher-allowed-exams .teacher-allowed-exams-submit-wrap {
  height: 37px;
}
#added-teachers .teacher-disqualifications .teacher-disqualifications-submit,
#added-teachers .teacher-allowed-exams .teacher-disqualifications-submit,
#added-teachers .teacher-disqualifications .teacher-allowed-exams-submit,
#added-teachers .teacher-allowed-exams .teacher-allowed-exams-submit {
  margin-top: 5px;
  float: right;
}
#added-teachers .teacher-disqualifications .teacher-disqualifications-list,
#added-teachers .teacher-allowed-exams .teacher-disqualifications-list,
#added-teachers .teacher-disqualifications .teacher-allowed-exams-list,
#added-teachers .teacher-allowed-exams .teacher-allowed-exams-list {
  display: inline-block;
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  margin-bottom: 4px;
}
#added-teachers .teacher-disqualifications .disqualifications-list-item,
#added-teachers .teacher-allowed-exams .disqualifications-list-item,
#added-teachers .teacher-disqualifications .allowed-exams-list-item,
#added-teachers .teacher-allowed-exams .allowed-exams-list-item {
  display: flex;
}
#added-teachers .teacher-disqualifications .disqualifications-value,
#added-teachers .teacher-allowed-exams .disqualifications-value,
#added-teachers .teacher-disqualifications .allowed-exams-value,
#added-teachers .teacher-allowed-exams .allowed-exams-value {
  flex: 1 1 auto;
}
#added-teachers .teacher-disqualifications .disqualifications-list-item a,
#added-teachers .teacher-allowed-exams .disqualifications-list-item a,
#added-teachers .teacher-disqualifications .allowed-exams-list-item a,
#added-teachers .teacher-allowed-exams .allowed-exams-list-item a {
  display: block;
  flex: 0 0 auto;
  margin-left: 10px;
}
.exam-server-code-container {
  margin-bottom: 20px;
}
.basic-table {
  width: 100%;
  font-size: 16px;
  background-color: white;
}
.basic-table th {
  border-bottom: 1px solid #dfdfdf;
  font-size: 14px;
  font-weight: 600;
  padding: 6px 1rem;
  text-align: left;
}
.basic-table td {
  padding: 6px 1rem;
  border-bottom: 1px solid #dfdfdf;
  vertical-align: top;
}
.basic-table th:first-child,
.basic-table td:first-child {
  padding-left: 2rem;
}
.basic-table th:last-child,
.basic-table td:last-child {
  padding-right: 2rem;
}
.basic-table thead th {
  height: 35px;
}
.unmapped-students {
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;
}
#exam-events {
  table-layout: fixed;
}
#exam-events td {
  border: 0;
}
#exam-events thead th {
  height: 35px;
  border-bottom: none;
}
#exam-events .exam-event-header-row th {
  border-top: 1px solid #dfdfdf;
  padding-bottom: 0;
  height: 39px;
  border-bottom: none;
}
#exam-events .event-date-col {
  width: 100px;
}
#exam-events .event-files-col {
  width: 250px;
}
#exam-events .name {
  color: #263d43;
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 20px;
  padding-top: 5px;
  max-width: 555px;
}
#exam-events button {
  width: calc(250px - 17px);
  padding: 1px 0;
}
#exam-events button:focus {
  outline: 0;
}
#exam-events button:active {
  color: #333333;
  background-color: #ededed;
}
#exam-events button:disabled {
  color: #999999;
  background-color: #f9f9f9;
}
#exam-events .exam-event .no-paper-exam-registrations {
  font-style: italic;
  color: #979797;
  font-size: 90%;
}
#exam-events .exam-event .exams-list,
#exam-events .exam-event .download-exam {
  padding: 0 10px 10px 10px;
}
#exam-events .exam-event .exams-row td {
  padding-top: 10px;
}
#exam-events .exam-event .exams-list .name {
  vertical-align: text-top;
}
#exam-events .exam-event .download-exam,
#exam-events .exam-event .download-grading-form {
  padding-right: 20px;
  vertical-align: top;
}
#exam-events .exam-event .no-meb-download-rights {
  font-style: italic;
  color: #979797;
  font-size: 90%;
}
#exam-events .exam-event .exams-of-event {
  margin: 0;
  padding: 0 0 0 0;
  font-size: 14px;
}
#exam-events .exam-event .exams-of-event .exam {
  list-style-type: none;
}
#exam-events .exam-event .exams-of-event .exam .name {
  padding: 0;
}
.digital-exams-publication-notification {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 7px;
}
#registration-tab h1 {
  margin-bottom: 10px;
}
@media print {
  #registration-tab .registrations-upload {
    display: none;
  }
}
#registration-tab .registrations-upload .registration-not-sent {
  margin-top: 10px;
  margin-bottom: 30px;
}
#registration-tab .registrations-upload .registration-upload-button-wrapper {
  margin-bottom: 16px;
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
}
#registration-tab .registrations-upload .registration-upload-button-wrapper .upload-input {
  top: -100000px;
}
#registration-tab .registrations-upload .registration-upload-button-wrapper .upload-button,
#registration-tab .registrations-upload .registration-upload-button-wrapper .dry-run-upload-button {
  align-self: center;
  min-width: 300px;
  margin: 0 0;
}
#registration-tab .registrations-upload .registration-upload-button-wrapper .upload-error {
  display: flex;
  align-self: center;
}
#registration-tab .registrations-upload .upload-takes-long-container {
  margin-left: 10px;
}
#registration-tab .dry-run-navi {
  margin-top: -45px;
  padding-bottom: 35px;
}
#registration-tab .dry-run-navi a {
  text-decoration: none;
  color: #6d6d6d;
}
#registration-tab .dry-run-navi a.selected {
  font-weight: bold;
}
#registration-tab .dry-run-navi a:first-child {
  margin-right: 30px;
}
#registration-tab .dry-run-results {
  border: 2px dashed rgba(0, 110, 210, 0.7);
  border-radius: 10px;
  padding: 30px;
}
#registration-tab .dry-run-title {
  font-weight: 600;
}
#registration-tab .dry-run-uploaded-info {
  font-size: 14px;
  margin-left: 20px;
}
#registration-tab .dry-run-no-errors:after {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f00c';
  color: green;
  margin-left: 0.5rem;
}
#registration-tab .dry-run-upload-button {
  width: 400px;
}
#registration-tab .override-modal {
  color: #263d43;
}
#registration-tab .override-button-sidenote {
  font-size: 12px;
  color: #6d6d6d;
  margin-left: 10px;
}
@media print {
  #registration-tab .action-buttons {
    display: none;
  }
}
#registration-tab .student-transfer input {
  width: 20em;
  padding: 8px;
}
#registration-tab .student-transfer .error {
  color: #c20019;
}
#registration-tab .student-transfer select {
  background-color: white;
  padding: 6px;
  border: solid lightgray 1px;
  margin-right: 10px;
}
@media print {
  #registration-tab .student-transfer {
    display: none;
  }
}
#registration-tab .student-transfer {
  margin-left: 5em;
  margin-right: 5em;
}
#registration-tab .fa-exclamation-triangle {
  color: #c20019;
}
#registration-tab .fa-exclamation-circle {
  color: #004DD0;
}
#registration-tab .registration-received-notification {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  border: #c8d4db 1px solid;
  border-radius: 3px;
  background-color: #f7fdff;
  color: #555555;
  padding: 15px;
}
#registration-tab .registration-received-notification .fa-check-circle {
  color: #5acd46;
  margin-right: 12px;
}
#registration-tab .info_container {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}
#registration-tab .info_container .unknown_student_name_info {
  font-style: italic;
  font-size: 16px;
  margin-left: 2px;
}
#registration-tab .registration-error {
  display: table;
}
#registration-tab .registration-error > div {
  display: table-cell;
  vertical-align: middle;
}
#registration-tab .registry-error-notification,
#registration-tab .registration-error-notification,
#registration-tab .registration-warning-notification {
  background-color: #fee9ea;
  color: #c20019;
  border: 1px solid lightgrey;
  padding: 14px;
  margin-top: 20px;
  display: flex;
}
#registration-tab .registry-error-notification .fa-exclamation-triangle,
#registration-tab .registration-error-notification .fa-exclamation-triangle,
#registration-tab .registration-warning-notification .fa-exclamation-triangle,
#registration-tab .registry-error-notification .fa-exclamation-circle,
#registration-tab .registration-error-notification .fa-exclamation-circle,
#registration-tab .registration-warning-notification .fa-exclamation-circle {
  display: flex;
  align-self: center;
}
#registration-tab .registry-error-notification .error-text,
#registration-tab .registration-error-notification .error-text,
#registration-tab .registration-warning-notification .error-text,
#registration-tab .registry-error-notification .warning-text,
#registration-tab .registration-error-notification .warning-text,
#registration-tab .registration-warning-notification .warning-text {
  margin-left: 14px;
}
#registration-tab .registration-warning-notification {
  color: black;
  background-color: #f0f0fe;
}
#registration-tab .registration-errors,
#registration-tab .registration-warnings {
  color: #c20019;
  padding-right: 85px;
}
#registration-tab .registration-errors .separator:not(:last-child),
#registration-tab .registration-warnings .separator:not(:last-child) {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #c20019;
  margin: 7px 0;
  padding: 0;
  width: 30px;
}
#registration-tab .registration-errors .separator:last-child,
#registration-tab .registration-warnings .separator:last-child {
  display: none;
}
#registration-tab .registration-warnings {
  color: black;
}
#registration-tab .registration-errors-table .fa-exclamation-triangle:after,
#registration-tab .registration-warnings-table .fa-exclamation-triangle:after,
#registration-tab .registration-errors-table .fa-exclamation-circle:after,
#registration-tab .registration-warnings-table .fa-exclamation-circle:after {
  content: ' ';
  white-space: pre;
}
#registration-tab .registration-errors-table th,
#registration-tab .registration-warnings-table th {
  padding-top: 5px;
  padding-bottom: 5px;
}
#registration-tab .accepted-registration-info {
  margin-top: 10px;
}
#registration-tab .registration-table {
  margin-top: 20px;
}
#registration-tab .registration-table td {
  vertical-align: top;
}
#registration-tab .registration-table .name-col {
  width: 230px;
}
@media print {
  #registration-tab .registration-table .name-col {
    width: 220px;
  }
}
#registration-tab .registration-table .name-col .lastname {
  font-weight: bold;
  line-height: 19px;
}
#registration-tab .registration-table .name-col .firstname {
  line-height: 19px;
}
#registration-tab .registration-table .pre2022student {
  background-image: url('../public/img/tutkintolaki-pre22-icon.svg');
  background-repeat: no-repeat;
  background-position: left;
  background-size: 50px 24px;
  height: 19px;
}
#registration-tab .registration-table .ssn-col {
  width: 140px;
}
@media print {
  #registration-tab .registration-table .ssn-col {
    width: 110px;
  }
}
#registration-tab .registration-table .name-col.unknown {
  font-style: italic;
}
#registration-tab .accepted-registrations-table th {
  vertical-align: bottom;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 3px solid #505050;
  margin-bottom: 16px;
  height: 54px;
}
#registration-tab .accepted-registrations-table th .student-fee-total {
  display: inline-block;
  font-weight: 400;
}
#registration-tab .accepted-registrations-table th .total-amount {
  font-weight: 700;
  text-align: right;
}
#registration-tab .accepted-registrations-table tbody tr:first-child td {
  padding-top: 0.5rem;
}
#registration-tab .accepted-registrations-table tbody tr.pre-2022-student td {
  padding-bottom: 0;
  border-bottom: 0 none;
}
#registration-tab .accepted-registrations-table tr.has-pre2022-student td {
  padding-top: 0;
}
#registration-tab .accepted-registrations-table .student-fee-col {
  text-align: right;
  font-weight: 700;
}
#registration-tab .accepted-registrations-table .student-transfer-code {
  position: absolute;
  background-color: white;
  padding: 1em;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.15);
  width: 70%;
  margin-top: 5px;
  z-index: 1;
}
#registration-tab .accepted-registrations-table .student-transfer-code .close {
  background-image: url('../public/img/rasti-icon-small.svg');
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  float: right;
  cursor: pointer;
}
#registration-tab .accepted-registrations-table .student-transfer-code .code {
  color: #004DD0;
}
@media print {
  #registration-tab .accepted-registrations-table .student-transfer-code {
    display: none;
  }
}
#registration-tab .accepted-registrations-table .invalidated-registration .student-number-col,
#registration-tab .accepted-registrations-table .invalidated-registration .exams-col,
#registration-tab .accepted-registrations-table .invalidated-registration .student-kind-col {
  color: #cdcdcd;
}
#registration-tab .accepted-registrations-table .student-number-col {
  width: 110px;
}
@media print {
  #registration-tab .accepted-registrations-table .student-number-col {
    width: 90px;
  }
}
#registration-tab .accepted-registrations-table .exams-col {
  width: 251px;
}
@media print {
  #registration-tab .accepted-registrations-table .exams-col {
    width: 190px;
  }
}
#registration-tab .accepted-registrations-table .student-kind-col {
  width: 95px;
}
#registration-tab .accepted-registrations-table .exams-table {
  width: 100%;
}
#registration-tab .accepted-registrations-table .exams-table .exam-cell {
  border-bottom: 0;
  padding: 0;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.modal-main {
  z-index: 1010;
  background: white;
  overflow-y: auto;
  max-width: 100%;
  max-height: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 80px 40px;
}
.modal-main div {
  padding-bottom: 10px;
}
.modal-main ol {
  padding-left: 15px;
}
.modal-main .grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.modal-main input,
.modal-main select,
.modal-main label {
  display: block;
}
.modal-main .modal-textarea {
  width: 100%;
  height: 60px;
  background: #ffffff;
  border: 1px solid #6d6d6d;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
.modal-main .education-type-select {
  width: 90%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #6d6d6d;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
.modal-main .modal-text-input {
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #6d6d6d !important;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
.modal-main .invalid-text-input {
  width: 388px;
  height: 40px;
  background: #ffffff;
  border: 1px solid red !important;
  outline-color: red !important;
  box-sizing: border-box;
  border-radius: 3px;
  box-shadow: 0px 1px 4px red;
}
.modal-main .text-block {
  margin-bottom: 20px;
}
.modal-main .confirm-checkbox {
  vertical-align: middle;
  display: inline;
  width: 40px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #6d6d6d;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin-right: 5px;
}
.modal-main .confirm-button {
  z-index: 1020;
  margin-right: 20px;
  border: 1px solid #0066cc;
  background: #3b9bb5;
  border-radius: 3px;
  color: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 40px;
  text-align: center;
}
.modal-main .confirm-button-disabled {
  z-index: 1020;
  margin-right: 20px;
  background: #767676 !important;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 40px;
  text-align: center;
  color: #ffffff !important;
}
.modal-main .cancel-button {
  z-index: 1020;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 40px;
  text-align: center;
  color: #359bb7;
  box-shadow: none;
  border: none;
}
.display-block {
  display: flex;
}
.display-none {
  display: none;
}
.euro-sign {
  display: inline-block;
  width: 19px;
  height: 19px;
  background: url('../public/img/euro-sign_euro.svg') no-repeat center;
  position: relative;
  top: 4px;
}
#examination-phase-tab h1 {
  margin-bottom: 10px;
}
#examination-phase-tab .fa-exclamation-triangle {
  color: #c20019;
}
#examination-phase-tab .fa-exclamation-circle {
  color: #004DD0;
}
#examination-phase-tab .student-errors {
  color: #c20019;
  padding-right: 85px;
}
#examination-phase-tab .student-errors .separator:not(:last-child) {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #c20019;
  margin: 7px 0;
  padding: 0;
  width: 30px;
}
#examination-phase-tab .student-errors .separator:last-child {
  display: none;
}
#examination-phase-tab .student-info {
  margin-top: 10px;
}
#examination-phase-tab .examination-phase-table {
  background-color: inherit;
  margin-top: 20px;
}
#examination-phase-tab .examination-phase-table .student-exam-details-table {
  width: 100%;
  margin-left: -10px;
}
#examination-phase-tab .examination-phase-table .student-exam-details-table tbody tr:first-child td {
  padding-top: 0;
}
#examination-phase-tab .examination-phase-table .student-exam-details-table tbody tr:last-child td {
  padding-bottom: 16px;
}
#examination-phase-tab .examination-phase-table .student-exam-details-table td {
  padding-left: 0px;
  padding-top: 0px;
  border-bottom: none;
}
#examination-phase-tab .examination-phase-table .student-exam-details-table td:last-child {
  padding-right: 0px;
}
#examination-phase-tab .examination-phase-table .student-exam-details-table tr:last-child {
  border-bottom: 0;
}
#examination-phase-tab .examination-phase-table .student-exam-details-table .failed-subject {
  color: #6d6d6d;
}
#examination-phase-tab .examination-phase-table .student-exam-details-table .retry-attempt {
  text-align: right;
}
#examination-phase-tab .examination-phase-table .student-exam-details-table .grade {
  width: 5%;
}
#examination-phase-tab .examination-phase-table .student-exam-details-table .subject {
  width: 30%;
}
#examination-phase-tab .examination-phase-table .student-exam-details-table .reg-code {
  width: 5%;
}
#examination-phase-tab .examination-phase-table .student-exam-details-table-border:not(:last-child) {
  border-bottom: 1px dashed #d3d3d3;
}
#examination-phase-tab .examination-phase-table th {
  vertical-align: bottom;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 3px solid #505050;
  background-color: #fafafa;
  margin-bottom: 16px;
  height: 54px;
}
#examination-phase-tab .examination-phase-table tbody tr:first-child td {
  padding-top: 0.5rem;
}
#examination-phase-tab .examination-phase-table th:first-child,
#examination-phase-tab .examination-phase-table td:first-child {
  padding-left: 1rem;
}
#examination-phase-tab .examination-phase-table tbody tr.pre-2022-student td {
  padding-bottom: 0;
  border-bottom: 0 none;
}
#examination-phase-tab .examination-phase-table td {
  vertical-align: middle;
}
#examination-phase-tab .examination-phase-table tr:last-child {
  border-bottom: 3px solid #505050;
}
#examination-phase-tab .examination-phase-table .student-kind-col {
  width: 95px;
}
#examination-phase-tab .examination-phase-table .name-col {
  width: 200px;
}
@media print {
  #examination-phase-tab .examination-phase-table .name-col {
    width: 170px;
  }
}
#examination-phase-tab .examination-phase-table .name-col .lastname {
  font-weight: bold;
  line-height: 19px;
}
#examination-phase-tab .examination-phase-table .name-col .firstname {
  line-height: 19px;
}
#examination-phase-tab .examination-phase-table .has-necessary-exams-col {
  width: 310px;
}
#examination-phase-tab .examination-phase-table .has-necessary-exams-col ul {
  margin-bottom: 0px;
  margin-top: 0px;
  list-style: none;
  padding: 0;
}
#examination-phase-tab .examination-phase-table .has-necessary-exams-col-incomplete li:before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f00d';
  color: orange;
  margin-right: 0.5rem;
}
#examination-phase-tab .examination-phase-table .has-necessary-exams-col-complete li:before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f00c';
  color: green;
  margin-right: 0.5rem;
}
#examination-phase-tab .examination-phase-table .student-kind-col {
  width: 95px;
}
#examination-phase-tab .examination-phase-table .restart-col {
  text-align: left;
}
#examination-phase-tab .examination-phase-table .graduation-notice-col {
  width: 210px;
}
@media print {
  #examination-phase-tab .examination-phase-table .graduation-notice-col {
    width: 180px;
  }
}
#examination-phase-tab .examination-phase-table .graduation-notice-btn {
  padding: 0;
  border: none;
  background: none;
  outline: none;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #18bc9c;
  box-shadow: none;
}
#examination-phase-tab .examination-phase-table .ssn-col {
  width: 130px;
}
@media print {
  #examination-phase-tab .examination-phase-table .ssn-col {
    width: 110px;
  }
}
#examination-phase-tab .examination-phase-table .expanded-row-header {
  margin: 0;
}
#examination-phase-tab .examination-phase-table .expanded-row-title {
  margin-top: 1.5rem;
  margin-bottom: 0px;
  margin-left: 5px;
  font-weight: bold;
}
#examination-phase-tab .examination-phase-table .expanded-row-title-last-round,
#examination-phase-tab .examination-phase-table .expanded-row-title-free-exams-left {
  margin-bottom: 0px;
  margin-left: 5px;
}
#examination-phase-tab .examination-phase-table .rejected-examination {
  color: #6d6d6d;
}
#examination-phase-tab .examination-phase-table .rejected-examination h1 {
  color: #6d6d6d;
}
#examination-phase-tab .examination-phase-table .examination-status-title {
  color: #6d6d6d;
  float: right;
  padding-right: 4px;
}
#examination-phase-tab .examination-phase-table .examination-status-title h2,
#examination-phase-tab .examination-phase-table .examination-status-title h3,
#examination-phase-tab .examination-phase-table .examination-status-title h4 {
  margin-top: 0px;
  margin-bottom: 0px;
}
#examination-phase-tab .examination-phase-table .included-exams-title {
  margin-top: 15px;
  margin-left: 5px;
}
#examination-phase-tab .examination-phase-table .expanded-row-table-examinationcode {
  padding-left: 5px;
  margin-bottom: 10px;
}
#examination-phase-tab .examination-phase-table .extratime-period {
  color: #6d6d6d;
}
#examination-phase-tab .examination-phase-table .pre2022student {
  background-image: url('../public/img/tutkintolaki-pre22-icon.svg');
  background-repeat: no-repeat;
  background-position: left;
  background-size: 50px 24px;
  height: 19px;
}
#examination-phase-tab .examination-phase-table .expand-link {
  font-size: 30px;
}
#examination-phase-tab .examination-phase-table .expanded-examination-phase-table {
  font-size: 30px;
  border: 0;
}
#examination-phase-tab .examination-phase-table .expanded-table-row {
  width: 100%;
  background: linear-gradient(#e4e4e4, #f4f4f4);
}
#examination-phase-tab .examination-phase-table th.show-examination-history {
  width: 150px;
  text-align: right;
}
#examination-phase-tab .examination-phase-table td.show-examination-history {
  background-color: #e4e4e4;
  width: 150px;
  text-align: center;
  padding: 0.5rem;
}
#examination-phase-tab .examination-phase-table .name-col.unknown {
  font-style: italic;
}
#exam-results-tab .info-text {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 7px;
}
#exam-results-tab .download-button-cell {
  text-align: right;
}
#answers-upload-panel .import-answers-instructions {
  margin-top: 0;
}
#answers-upload-panel .answers-upload-button-wrapper {
  position: relative;
  display: inline-block;
}
.answers-uploaded-notification {
  border: #c8d4db 1px solid;
  border-radius: 3px;
  background-color: #f7fdff;
  padding: 7px 30px 6px;
  font-size: 14px;
  font-weight: 400;
  display: none;
  margin-bottom: 30px;
  padding: 12px 20px 20px;
  line-height: 20px;
}
.answers-uploaded-notification #answers-uploaded-close {
  font-family: 'Source Sans 3', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  background: #fefefe;
  color: #6d6d6d;
  border: 1px #c8c8c8 solid;
  box-shadow: 0 0 3px #ddd;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  border-radius: 2px;
  margin: 0;
  width: auto;
  height: auto;
  white-space: nowrap;
  float: right;
  padding: 1px 20px;
  min-width: 100px;
}
.answers-uploaded-notification #answers-uploaded-close[disabled],
.answers-uploaded-notification #answers-uploaded-close.disabled {
  border-color: #dfdfdf;
  color: #dfdfdf;
  background-color: #fefefe;
  box-shadow: none;
}
.answers-uploaded-notification #answers-uploaded-close[disabled]:hover,
.answers-uploaded-notification #answers-uploaded-close.disabled:hover {
  border-color: #dfdfdf;
  color: #dfdfdf;
  background-color: #fefefe;
  box-shadow: none;
}
.answers-uploaded-notification #answers-uploaded-close:hover {
  background-color: #f8f8f8;
  color: #333333;
}
.answers-uploaded-notification .clear-both {
  clear: both;
}
.answers-uploaded-notification .upload-notification-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  padding-bottom: 3px;
}
.answers-uploaded-notification .school-title > td {
  padding: 15px 0 5px;
}
.answers-uploaded-notification .without-school {
  padding-bottom: 3px;
}
.answers-uploaded-notification td.answer-count {
  text-align: right;
  vertical-align: top;
}
.answers-uploaded-notification td.exam-title {
  padding-right: 35px;
}
.upload-spinner {
  position: absolute;
  top: 20px;
  left: 240px;
}
.upload-input {
  visibility: hidden;
  position: absolute;
}
.upload-takes-long-container {
  display: inline-block;
  position: relative;
  border: #c8d4db 1px solid;
  border-radius: 3px;
  background-color: #f7fdff;
  padding: 7px 30px 6px;
  font-size: 14px;
  font-weight: 400;
}
.upload-takes-long-container .notification-text {
  margin-left: 5px;
}
.upload-takes-long-container .upload-spinner {
  position: absolute;
  top: 18px;
  left: 18px;
}
.held-exams-table.is_censor .for_in-pregrading {
  display: none !important;
}
.held-exams-table.is_censor .for_grading-active {
  display: none !important;
}
.held-exams-table.is_censor .for_grading-finished {
  display: none !important;
}
.held-exams-table.is_censor .is_in-pregrading .for_in-pregrading {
  display: inline-block !important;
}
.held-exams-table.is_censor .is_grading-active .for_grading-active {
  display: inline-block !important;
}
.held-exams-table.is_censor .is_grading-finished .for_grading-finished {
  display: inline-block !important;
}
.held-exams-table.is_censor .is_grading-finished .for_grading-finished.grading-progress,
.held-exams-table.is_censor .is_grading-active .for_grading-active.grading-progress {
  display: flex !important;
}
.held-exams-table.is_censor td:nth-child(8) {
  text-align: left;
}
.held-exams-table.is_censor td:last-child {
  width: 55px;
}
.held-exams-table.is_pregrading td.progress {
  padding: 1rem 0;
}
.held-exams-table.is_pregrading .for_grading-active {
  display: none !important;
}
.held-exams-table.is_pregrading .for_grading-finished {
  display: none !important;
}
.held-exams-table.is_pregrading .is_grading-active .for_grading-active {
  display: inline-block !important;
  margin-right: auto;
  margin-left: auto;
}
.held-exams-table.is_pregrading .is_grading-finished .for_grading-finished {
  display: inline-block !important;
  margin-right: auto;
  margin-left: auto;
}
.held-exams-table.is_collapsed .for_expanded {
  display: none;
}
.held-exams-table.is_expanded .for_collapsed {
  display: none;
}
tr.disabled * {
  color: black;
}
tr.disabled > * {
  opacity: 0.25;
}
tr.disabled td.work-left.late {
  animation: none;
}
tr.disabled td.work-left div.late {
  display: none;
}
.held-exams-table th .pregrading .before {
  display: inline-block;
  content: '';
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 10px;
}
.held-exams-table th .pregrading_graded:before {
  display: inline-block;
  content: '';
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 10px;
  background: hsl(218, 58%, 76%);
}
.held-exams-table th .pregrading_finished:before {
  display: inline-block;
  content: '';
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 10px;
  background: hsl(218, 28%, 39%);
}
.held-exams-table td {
  position: relative;
  line-height: 18px;
  vertical-align: middle;
}
.held-exams-table td.work-left {
  font-size: 14px;
}
.held-exams-table td.work-left b {
  font-size: 16px;
}
.held-exams-table td.work-left .target-date {
  display: flex;
  gap: 0.5rem;
  white-space: nowrap;
  font-weight: 700;
}
.held-exams-table td.work-left .date {
  width: 60px;
  font-size: 16px;
}
.held-exams-table td.work-left .late {
  padding: 0 5px;
  background: #ea0b0b;
  color: white;
  font-weight: 700;
  display: inline-block;
  border-radius: 4px;
  margin-top: 1px;
}
.held-exams-table td.work-left .green {
  color: #00b15c;
  font-weight: 600;
}
.held-exams-table .header-school-exam-anon-code,
.held-exams-table .header-school-number {
  width: 85px;
}
.held-exams-table .header-school-name {
  width: auto;
}
.held-exams-table .header-index,
.held-exams-table .header-held-answers,
.held-exams-table .header-students {
  width: 55px;
}
.held-exams-table .header-additional-censor-grading {
  text-align: left;
}
.held-exams-table .header-link-to-grades {
  font-size: 14px;
  text-align: center;
}
.held-exams-table td .exam-with-no-grader {
  color: red;
  white-space: nowrap;
}
.held-exams-table td.productive-progress {
  width: auto;
}
.held-exams-table td.productive-progress .grading-progress {
  justify-content: flex-start;
}
.held-exams-table td.productive-progress .grading-completed {
  width: auto;
}
.held-exams-table .grading-completed {
  font-weight: 400;
  text-align: center;
}
.held-exams-table .total-progress {
  font-weight: 400;
}
.held-exams-table .total-progress .progress-bg {
  display: inline-block;
  margin-left: 8px;
  position: relative;
  top: 1px;
}
.held-exams-table .total-progress .separate {
  margin: 0 5px;
}
#first-level-censoring .held-exams-table.paper-exam .first-level-censoring-status,
.next-level-censoring.held-exams-table .exam-row .first-level-censoring-status {
  width: 88px;
  text-align: center;
}
#first-level-censoring .held-exams-table.paper-exam .to-grading,
.next-level-censoring.held-exams-table .exam-row .to-grading {
  width: 100px;
}
#first-level-censoring .held-exams-table.paper-exam .button,
.next-level-censoring.held-exams-table .exam-row .button {
  font-family: 'Source Sans 3', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  background: #fefefe;
  color: #6d6d6d;
  border: 1px #c8c8c8 solid;
  box-shadow: 0 0 3px #ddd;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  border-radius: 2px;
  margin: 0;
  padding: 1px 20px;
  width: auto;
  height: auto;
  white-space: nowrap;
  display: inline-block;
  text-decoration: none;
}
#first-level-censoring .held-exams-table.paper-exam .button[disabled],
.next-level-censoring.held-exams-table .exam-row .button[disabled],
#first-level-censoring .held-exams-table.paper-exam .button.disabled,
.next-level-censoring.held-exams-table .exam-row .button.disabled {
  border-color: #dfdfdf;
  color: #dfdfdf;
  background-color: #fefefe;
  box-shadow: none;
}
#first-level-censoring .held-exams-table.paper-exam .button[disabled]:hover,
.next-level-censoring.held-exams-table .exam-row .button[disabled]:hover,
#first-level-censoring .held-exams-table.paper-exam .button.disabled:hover,
.next-level-censoring.held-exams-table .exam-row .button.disabled:hover {
  border-color: #dfdfdf;
  color: #dfdfdf;
  background-color: #fefefe;
  box-shadow: none;
}
#first-level-censoring .held-exams-table.paper-exam .button:hover,
.next-level-censoring.held-exams-table .exam-row .button:hover {
  background-color: #f8f8f8;
  color: #333333;
}
.exam-title-collapse-link-text {
  color: #263d43;
}
.exam-title-collapse-link-content {
  display: inline-flex;
  align-items: center;
  color: #263d43;
}
.exam-title-collapse-link-content .details {
  min-width: 120px;
  flex: 0 1 auto;
}
.exam-title-collapse-link-content .progress {
  min-width: 100px;
  flex: 0 1 auto;
}
.exam-title-collapse-link-content .progress-bg {
  display: inline-flex;
}
.exam-title-collapse-link-content .progress-text {
  width: auto;
}
.exam-title-collapse-link-content .name {
  margin-left: 20px;
  flex: 0 1 auto;
}
.next-level-censoring-tables,
.grading-inspection-tables {
  display: table;
  width: 100%;
}
.next-level-censoring-tables .next-level-censoring tbody .button,
.grading-inspection-tables .next-level-censoring tbody .button {
  font-family: 'Source Sans 3', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  background: #fefefe;
  color: #6d6d6d;
  border: 1px #c8c8c8 solid;
  box-shadow: 0 0 3px #ddd;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  border-radius: 2px;
  margin: 0;
  padding: 1px 20px;
  width: auto;
  height: auto;
  white-space: nowrap;
  display: inline-block;
  text-decoration: none;
}
.next-level-censoring-tables .next-level-censoring tbody .button[disabled],
.grading-inspection-tables .next-level-censoring tbody .button[disabled],
.next-level-censoring-tables .next-level-censoring tbody .button.disabled,
.grading-inspection-tables .next-level-censoring tbody .button.disabled {
  border-color: #dfdfdf;
  color: #dfdfdf;
  background-color: #fefefe;
  box-shadow: none;
}
.next-level-censoring-tables .next-level-censoring tbody .button[disabled]:hover,
.grading-inspection-tables .next-level-censoring tbody .button[disabled]:hover,
.next-level-censoring-tables .next-level-censoring tbody .button.disabled:hover,
.grading-inspection-tables .next-level-censoring tbody .button.disabled:hover {
  border-color: #dfdfdf;
  color: #dfdfdf;
  background-color: #fefefe;
  box-shadow: none;
}
.next-level-censoring-tables .next-level-censoring tbody .button:hover,
.grading-inspection-tables .next-level-censoring tbody .button:hover {
  background-color: #f8f8f8;
  color: #333333;
}
.next-level-censoring-tables .next-level-censoring tbody .completed,
.grading-inspection-tables .next-level-censoring tbody .completed {
  color: #999;
}
.grading-inspection-tables td:nth-child(1) {
  text-align: left;
  width: 82%;
}
.grading-inspection-tables td:nth-child(2) {
  text-align: left;
  width: 18%;
}
.grading-inspection-tables td:last-child {
  text-align: right;
  width: 10%;
}
.grading-inspection-tables .link-to-inspection.button {
  font-family: 'Source Sans 3', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  background: #fefefe;
  color: #6d6d6d;
  border: 1px #c8c8c8 solid;
  box-shadow: 0 0 3px #ddd;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  border-radius: 2px;
  margin: 0;
  padding: 1px 20px;
  width: auto;
  height: auto;
  white-space: nowrap;
  display: inline-block;
  text-decoration: none;
}
.grading-inspection-tables .link-to-inspection.button[disabled],
.grading-inspection-tables .link-to-inspection.button.disabled {
  border-color: #dfdfdf;
  color: #dfdfdf;
  background-color: #fefefe;
  box-shadow: none;
}
.grading-inspection-tables .link-to-inspection.button[disabled]:hover,
.grading-inspection-tables .link-to-inspection.button.disabled:hover {
  border-color: #dfdfdf;
  color: #dfdfdf;
  background-color: #fefefe;
  box-shadow: none;
}
.grading-inspection-tables .link-to-inspection.button:hover {
  background-color: #f8f8f8;
  color: #333333;
}
.grading-inspection-tables .empty,
.grading-inspection-tables .loading {
  text-align: center !important;
}
.next-level-censoring-tables .first-level-censor {
  text-align: left;
}
.next-level-censoring-tables .next-level-censoring {
  border-top: none;
}
.next-level-censoring-tables .held-exams-table {
  box-shadow: none;
}
.next-level-censoring-tables .link-to-grades {
  display: inline-block;
}
.next-level-censoring-tables .next-level-censoring-info {
  font-size: 16px;
  text-align: left;
}
.fetch-exams-spinner-row {
  height: 38px;
  display: none;
}
#exam-teachers-tab .teachers-description {
  margin-top: 0;
  margin-bottom: 30px;
}
#exam-teachers-tab .io-error {
  position: fixed;
  top: 0;
  background: #5a99b3;
  width: 100%;
  left: 0;
  height: 62px;
  text-align: center;
  line-height: 62px;
  color: white;
  cursor: pointer;
}
#credentials-tab {
  display: flex;
  gap: 3rem;
  flex-direction: column;
}
#credentials-tab > div {
  width: 50%;
}
#credentials-tab .api-key-container {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  margin: 1rem 0;
  padding: 0.5rem;
  background: hsl(218, 61%, 88%);
  border-radius: 3px;
}
#credentials-tab .api-key-container input {
  border: 4px solid #fff;
  border-radius: 3px;
}
#credentials-tab .copied {
  color: #049c69;
  position: absolute;
  margin-top: 1.7rem;
  margin-left: 1rem;
}
#credentials-tab .missing {
  color: hsl(8, 85%, 47%);
  margin-top: 1rem;
}
#credentials-tab .api-key {
  font-family: 'Courier New', Courier, 'Lucida Sans Typewriter', 'Lucida Typewriter', monospace;
}
#eula-show-current-button {
  font-family: 'Source Sans 3', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-decoration: underline;
  color: #004DD0;
  width: auto;
  width: initial;
  height: auto;
  height: initial;
  text-align: left;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  box-shadow: none;
  outline: none;
  font-size: 17px;
  margin-left: 2rem;
  float: right;
  margin-top: 33px;
}
#eula-show-current-button i {
  padding-right: 6px;
}
#eula-show-current-button:hover {
  color: #032E78;
}
.censor-payments-link {
  display: block;
  float: right;
  margin-top: 33px;
  line-height: 32px;
}
.toggle-enter {
  opacity: 0;
  transform: scale(0.9);
}
.toggle-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 200ms,
    transform 200ms;
}
.toggle-exit {
  opacity: 1;
}
.toggle-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 200ms,
    transform 200ms;
}
#eula-dialog-base {
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  margin: 30px auto 30px auto;
  padding: 60px 80px 20px 80px;
}
#eula-dialog-base .header {
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  color: #505050;
}
#eula-dialog-base #eula-display-container {
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}
#eula-dialog-base #eula-display-container button {
  width: 260px;
}
#eula-dialog-base #eula-agreement-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
#eula-dialog-base #eula-agreement-container [for='eula-agreement-checkbox'] {
  display: flex;
  justify-content: space-between;
}
#eula-dialog-base #eula-agreement-container .eula-checkbox-container div {
  font-weight: 600;
}
#eula-dialog-base #eula-agreement-container .eula-checkbox-container input[type='checkbox'] {
  display: none;
}
#eula-dialog-base #eula-agreement-container .eula-checkbox-container label {
  cursor: pointer;
}
#eula-dialog-base #eula-agreement-container .eula-checkbox-container input[type='checkbox'] + label .eula-custom-checkbox {
  width: 30px;
  min-width: 30px;
  margin-right: 25px;
  height: 30px;
  display: block;
  border: 2px solid #aaaaaa;
  border-radius: 5px;
  background-color: #fafafa;
  position: relative;
  top: 25px;
  cursor: pointer;
}
#eula-dialog-base #eula-agreement-container .eula-checkbox-container input[type='checkbox']:checked + label .eula-custom-checkbox {
  background: #fafafa url('../public/img/check-mark.svg') no-repeat center;
}
.text-muted {
  opacity: 0.5;
}
dl {
  display: grid;
  grid-template-columns: auto auto;
}
dt {
  grid-column: 1;
}
dd {
  grid-column: 2;
  white-space: nowrap;
}
.js-invoice-table td {
  text-align: right;
}
@media print {
  #pagebanner {
    display: none !important;
  }
  #tab-bar {
    display: none;
  }
}
.result-list-component-container {
  max-width: 1500px;
  margin: 0 auto;
}
.result-list-component-container h1 {
  padding-left: 10px;
}
.result-list-component-container .description {
  padding-left: 10px;
  margin-bottom: 32px;
  max-width: 860px;
}
.result-list-component-container .tab-navigation {
  position: sticky;
  top: 0;
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.result-list-component-container .tab-navigation::before {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(0deg, #020024 0%, #ededed 0%, #fafafa 100%);
  width: 100vw;
  margin-left: calc(50% - 50vw);
}
.result-list-component-container .tab-navigation a {
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  color: #6d6d6d;
  text-decoration: none;
  padding: 3px 0;
  margin-right: 10px;
  min-width: 100px;
  text-align: center;
}
.result-list-component-container .tab-navigation a:hover {
  color: #000;
}
.result-list-component-container .tab-navigation a.active {
  border-bottom: 3px solid #359bb7;
  color: #000;
}
.student-exam-results .header {
  font-weight: normal;
  padding: 10px;
  border-bottom: 1px #000 solid;
}
.student-exam-results .header .birthday {
  display: none;
}
.student-exam-results .header .birthday.no-ssn {
  display: inline-block;
}
.student-exam-results .header > span {
  padding-right: 10px;
}
.student-exam-results .header .ssn {
  margin-left: 1rem;
}
.student-exam-results .header .total-grade-points {
  float: right;
  font-weight: bold;
  text-align: right;
  padding-right: 0;
}
.student-exam-results div.examination-results {
  padding: 10px;
}
.student-exam-results div.examination-results table {
  width: auto;
  padding-right: 8px;
}
.student-exam-results div.examination-results td,
.student-exam-results div.examination-results th {
  text-align: left;
}
.student-exam-results div.examination-results td {
  white-space: nowrap;
  font-size: 16px;
}
.student-exam-results div.examination-results td.exam-name {
  min-width: 170px;
}
.student-exam-results div.examination-results td.exam-code {
  min-width: 32px;
  padding: 5px 0;
}
.student-exam-results div.examination-results td.exam-required {
  min-width: 64px;
}
.student-exam-results div.examination-results td.exam-gradecode {
  min-width: 26px;
}
.student-exam-results div.examination-results td.exam-score {
  min-width: 75px;
}
.student-exam-results div.examination-results td:last-child {
  text-align: right;
  width: 100%;
}
.student-exam-results div.examination-results td:last-child .exam-technical-error {
  text-align: right;
  padding-right: 10px;
  font-size: 12px;
  color: #a6a6a6;
}
.student-exam-results div.examination-results td:last-child .exam-status {
  padding-right: 10px;
  font-size: 12px;
}
.student-exam-results div.examination-results th.question-score,
.student-exam-results div.examination-results td.question-score {
  padding: 0 4px;
  text-align: center;
  min-width: 24px;
  font-size: 14px;
}
.student-exam-results div.examination-results th.question-score:nth-child(even),
.student-exam-results div.examination-results td.question-score:nth-child(even) {
  background: #eee;
}
.student-exam-results div.examination-results th.question-score {
  font-weight: normal;
  font-size: 10px;
  font-style: italic;
  padding-bottom: 0;
  border-radius: 15px 15px 0 0;
}
.student-exam-results div.examination-results tr.not-best-result {
  color: #a6a6a6;
}
.student-exam-results div.examination-results tr.last-completed-exam td {
  border-radius: 0 0 15px 15px;
}
@media print {
  .result-list-component-container > .tab-navigation {
    display: none;
  }
  .result-list-component-container .student-exam-results {
    page-break-inside: avoid;
  }
  .result-list-component-container .student-exam-results tr,
  .result-list-component-container .student-exam-results td {
    font-size: 12px;
    line-height: 18px;
  }
  .result-list-component-container .student-exam-results td.exam-code {
    min-width: 24px;
    padding: 3px 0;
  }
  .result-list-component-container .student-exam-results td.exam-name {
    min-width: 120px;
  }
  .result-list-component-container .student-exam-results td.exam-score {
    min-width: 48px;
  }
  .result-list-component-container .student-exam-results td.exam-required {
    min-width: 48px;
  }
  .result-list-component-container .student-exam-results th.question-score,
  .result-list-component-container .student-exam-results td.question-score {
    padding: 0 2px;
    min-width: 12px;
    max-width: 18px;
    font-size: 8px;
  }
  .result-list-component-container .student-exam-results td:last-child .exam-status {
    padding-right: 8px;
    font-size: 8px;
  }
  .result-list-component-container .student-exam-results .birthday {
    display: inline-block !important;
  }
  .result-list-component-container .student-exam-results .ssn {
    display: none;
  }
  .result-list-component-container .student-exam-results .statements {
    display: none;
  }
  .result-list-component-container .question-score {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
}
