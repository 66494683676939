@import 'base';

/**********************
  Yo colors
***********************/
@base-brand-blue: #004DD0;
@base-banner-background-blue: #fafafa;
@base-link-font-blue-hover: #032E78;
@base-blue-button: @base-brand-blue;
@base-blue-button-hover: @base-link-font-blue-hover;
@base-disabled-button: #c8c8c8;
@base-disabled-text: #6d6d6d;
@base-error-background: rgb(254, 233, 234);
@base-error-text: rgb(194, 0, 25);
@base-page-banner-text-color: @base-color-almost-black;
@text-color: #263d43;
@input-text-weight: 400;

.utf-char-before(@content, @left: 0) {
  &:before {
    font-weight: 400;
  }
}

.centered-content() {
  margin: 80px auto 80px;
}

body {
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: auto;
}

#pagebanner {
  width: 100%;
  background: white;
  border-bottom: 1px solid @base-disabled-button;
  .banner-content {
    height: 60px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    max-width: @layout-width;
    margin: 0 auto;
    padding-left: @small-screen-margin;
    padding-right: @small-screen-margin;
    color: @SAPageBannerTextColor;
    font-size: 14px;

    .banner-left {
      background: url('../public/img/pallasathene-icon-header.png') no-repeat left center;
      padding-left: 70px;
      height: 60px;
      display: -webkit-flex;
      display: flex;
      align-items: center;
      span {
        color: @base-page-banner-text-color;
        font-size: 20px;
        font-weight: 400;
      }
    }

    .user-area {
      display: -webkit-flex;
      display: flex;
      align-items: center;
    }
  }
}

#page-content {
  margin-top: 16px;
  padding-left: @small-screen-margin;
  padding-right: @small-screen-margin;
}

.login-notification {
  color: @base-error-text;
  display: none;
}



@login-button-padding: 50px;
#vetuma-login {
  h3 {
    margin-bottom: 0px;
  }
  .login-method-list {
    list-style-position: inside;
    padding-left: 0px;
    margin-top: 8px;
    margin-bottom: 24px;
  }
  .login {
    width: auto;
    padding-left: @login-button-padding;
    padding-right: @login-button-padding;
  }
}

.back-to-login {
  width: auto;
  padding-left: @login-button-padding;
  padding-right: @login-button-padding;
}

.login-error {
  display: none;
  @login-error-vertical-padding: 12px;
  margin-bottom: 24px;
  text-align: center;
  color: @base-error-text;
  background-color: @base-error-shadow-transparent;
  margin-left: 420px;
  padding-top: @login-error-vertical-padding;
  padding-bottom: @login-error-vertical-padding;
}



.add-teacher-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  label {
    margin-right: 16px;
  }
  label span {
    font-size: 16px;
    font-weight: 600;
    display: block;
  }
  input.teacher-ssn,
  input.teacher-name {
    @text-padding: 12px;
    padding-left: @text-padding;
    padding-right: @text-padding;
    height: 40px;
    line-height: 40px;
    width: 220px;
  }
  button {
    margin: 0;
    margin-top: 24px;
  }
  .loader {
    height: 40px;
    font-height: 13px;
    display: flex;
    margin-left: 10px;
    align-items: center;
  }

  .teacher-new-allowed-exams {
    display: flex;
    flex-direction: column;

    .teacher-new-allowed-exams-input {
      margin-top: -5px;
      height: 40px;
      width: 250px;
    }
    .teacher-new-allowed-exams-submit-wrap {
      height: 37px;
    }
    .teacher-new-allowed-exams-submit {
      margin-top: 5px;
      float: right;
    }
    .teacher-new-allowed-exams-list {
      display: inline-block;
      list-style-type: none;
      padding: 0px;
      margin: 0px;
      margin-bottom: 4px;
    }
    .new-allowed-exams-list-item {
      display: flex;
    }
    .new-allowed-exams-value {
      flex: 1 1 auto;
    }
    .new-allowed-exams-list-item a {
      display: block;
      flex: 0 0 auto;
      margin-left: 10px;
    }
  }
}

#added-teachers {
  td {
    vertical-align: top;
  }
  th:nth-child(2) {
    width: 300px;
  }
  th:nth-child(3) {
    min-width: 300px;
  }
  th:nth-child(4) {
    min-width: 300px;
  }
  .no-teacher-name {
    font-style: italic;
    color: @base-disabled-text;
  }
}

#no-teachers {
  font-style: italic;
  color: @base-disabled-text;
}

.input-error {
  background-color: @base-error-background !important;
}
#add-teacher-validation-error {
  position: absolute;
  @popup-width: 300px;
  min-width: @popup-width;
  margin-left: -40px;
  margin-top: 12px;
  z-index: 100;
  vertical-align: top;
  background: @base-error-background;
  padding: 10px 15px;
  color: @base-error-text;
  font-size: 14px;
  text-align: center;
  border: 1px solid @base-error-text;
  border-radius: 3px;
  .hidden {
    display: block;
  }
  &:before {
    @triangle-size: 11px;
    content: '';
    width: @triangle-size;
    height: @triangle-size;
    background-color: @base-error-background;
    border: 1px solid @base-error-text;
    position: absolute;
    top: -6px;
    left: ((@popup-width / 2) - (@triangle-size / 2));
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &:after {
    content: '';
    background-color: @base-error-background;
    width: 100px;
    height: 15px;
    position: absolute;
    left: ((@popup-width / 2) - (100 / 2));
    top: 0;
  }
}
